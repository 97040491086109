import React, { Component, MouseEvent } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { AxiosError, AxiosResponse } from "axios";
import DataGrid, { Paging, HeaderFilter, FilterRow, Column, Export, FilterPanel, GroupPanel, Pager, SearchPanel, Selection, StateStoring, Scrolling } from "devextreme-react/data-grid";
import UserService, { RoleGroupNames } from "../../services/UserService";
import { Format } from "devextreme-react/bar-gauge";
import SimpleGridService, { GridTypes, ShiftMatrixRemoteGridRowItem } from "../../services/SimpleGridService";
import ApproveActionCell from "../GridCells/ApproveActionCell";
import QueryActionCell from "../GridCells/QueryActionCell";
import ChangeShiftMatrixCell from "../GridCells/ChangeShiftMatrixCell";
import ViewShiftMatrixCell from "../grid/ViewShiftMatrixCell";
import { DataGridOnSelectionChanged, onExportingEvent, DateBoxOnValueChangedEvent } from "../../types/DevExtremeTypes";
import { Workbook } from "exceljs";
import { exportDataGrid } from "devextreme/excel_exporter";
import DeleteEditShiftActionCellV2 from "../GridCells/DeleteEditShiftActionCellV2";
import GlobalService from "../../services/GlobalService";
import AllUserGridService from "../grid/AllUserGridService";
import CookieService, { DXGridCookieKeyTypes } from "../../services/CookieService";
import CostReportUtils from "../CostReports/CostReportUtils";
import { itemTypes } from "../Outstanding-Actions/OutstandingActionsUtilities";
import AllUserGridShift from "../grid/ManageViewShiftGridRowCell/AllUserGridShiftCellComponent";
import StarRating from "../FindAnArtist/starRating";
import { Link } from "react-router-dom";
import { DateBox, Popup, ScrollView, LoadPanel } from "devextreme-react";
import ArtistProfileComponent from "../Artist-Profile/ArtistProfileComponent";
import { confirm } from "devextreme/ui/dialog";
import SupplierInformationMainContent from "../SupplierInformation/SupplierInformationMainContent";
import CustomStore from "devextreme/data/custom_store";
import { buildRequestParameters } from "../../services/RemoteOperationsService";
import PaymentService, { paymentStatus } from "../../services/PaymentService";
import moment from "moment";

interface ShiftMatrixGridContentsProps extends WithTranslation {
    dataSource: ShiftMatrixRemoteGridRowItem[],
    navigateFrom: string,
    paymentId?: string,
    providerId?: string,
    payDate?: Date | null | undefined,
    updateClicked?: (isClicked: boolean) => void;
    onRefresh?: (flag: boolean) => void;
    defaultPageSize?:number
    getSelectedRows?:(selectedData:any)=>void;
    isAddShiftClicked?:boolean;
}

interface ShiftMatrixGridContentsState {
    feedbackCommentsPopupVisible: boolean;
    feedbackComments: string;
    internalCommentsPopupVisible: boolean,
    internalComments: string,
    commentsPopupVisible: boolean,
    externalComments: string,
    providerPopupVisibleEntertainment?: boolean;
    providerPopupVisibleOther?: boolean;
    clientId: string;
    venueId: string;
    serviceTypeId: string;
    businessEntityId: string;
    negativeShiftsPopupVisible: boolean,
    futureDataSource: [],
    futureShiftsData: [],
    linkedId: [],
    paymentCycleIds: string[],
    alignDate: Date | null | undefined,
    showContinuePopup: boolean,
    showErrorPopup: boolean,
    loadPanelVisible: boolean,
    serviceName: string,
}

class ShiftMatrixGridContents extends Component<ShiftMatrixGridContentsProps> {
    state: ShiftMatrixGridContentsState;
    simpleGridService: SimpleGridService;
    paymentService: PaymentService;
    costReportUtils: CostReportUtils;
    remoteOperations: {
        sorting: boolean,
        paging: boolean,
        filtering: boolean,
        grouping: boolean,
        summary: boolean
    }
    isEditMode: string;
    providerId: string;
    dataGrid:any;
    gridRef:any;
    userHasAccess: boolean;
    constructor(props: ShiftMatrixGridContentsProps) {
        super(props);
        this.isEditMode = "";
        this.providerId = "";
        this.dataGrid = null;
        this.gridRef = null;
        this.userHasAccess =
            UserService.isUserInGroup(RoleGroupNames.EventUKSeniorManager) ||
            UserService.isUserInGroup(RoleGroupNames.EventUKRelationshipManager);
        this.simpleGridService = new SimpleGridService();
        this.paymentService = new PaymentService();
        this.state = {
            feedbackCommentsPopupVisible: false,
            feedbackComments: "",
            internalCommentsPopupVisible: false,
            internalComments: "",
            commentsPopupVisible: false,
            externalComments: "",
            providerPopupVisibleEntertainment: false,
            providerPopupVisibleOther: false,
            clientId: "",
            venueId: "",
            serviceTypeId: "",
            businessEntityId: "",
            negativeShiftsPopupVisible: false,
            futureDataSource: [],
            futureShiftsData: [],
            linkedId: [],
            paymentCycleIds: [],
            alignDate: undefined,
            showContinuePopup: false,
            showErrorPopup: false,
            loadPanelVisible: false,
            serviceName: "",
        }
        this.costReportUtils = new CostReportUtils();
        this.remoteOperations = {
            sorting: true,
            paging: true,
            filtering: true,
            grouping: true,
            summary: false
        }
    }

    componentDidUpdate(prevProps: ShiftMatrixGridContentsProps,prevState: ShiftMatrixGridContentsState) {
        if(this.props.navigateFrom == GridTypes.addShiftBillingGrid){
            if(this.props.isAddShiftClicked !== prevProps.isAddShiftClicked && this.props.isAddShiftClicked == true){
                this.gridRef?.instance?.clearSelection();

            }
            
        }
    }

    showStringToCurrencyFormat = (value: string) => {
        var valuewithoutPoundSymbol: number = 0;
        if (value && value.includes("£")) {
            valuewithoutPoundSymbol = parseFloat(value.replace("£", ""));
        }
        return valuewithoutPoundSymbol;
    };

    onExporting = (e: onExportingEvent) => {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet("Main sheet");
        exportDataGrid({
            component: e.component,
            worksheet: worksheet,
            autoFilterEnabled: true,
            customizeCell: ({ gridCell, excelCell }) => {
                if (gridCell && gridCell.rowType === "totalFooter") {
                    if (gridCell.value && gridCell.totalSummaryItemName == "providerPayAmountLocal") {
                        excelCell.value = parseFloat(gridCell.value);
                        excelCell.numFmt = "£#,##0.00";
                    }
                    if (gridCell.value && gridCell.totalSummaryItemName == "providerPayAmountTaxLocal") {
                        excelCell.value = parseFloat(gridCell.value);
                        excelCell.numFmt = "£#,##0.00";
                    }
                    if (gridCell.value && gridCell.totalSummaryItemName == "providerPayAmountTotalLocal") {
                        excelCell.value = parseFloat(gridCell.value);
                        excelCell.numFmt = "£#,##0.00";
                    }
                    if (gridCell.value && gridCell.totalSummaryItemName == "fee_Amount_Local_Ccy") {
                        excelCell.value = parseFloat(gridCell.value);
                        excelCell.numFmt = "£#,##0.00";
                    }
                    if (gridCell.value && gridCell.totalSummaryItemName == "providerPayAmountAccrual") {
                        excelCell.value = parseFloat(gridCell.value);
                        excelCell.numFmt = "£#,##0.00";
                    }
                    if (gridCell.value && gridCell.totalSummaryItemName == "grossProfitAmountAccrual") {
                        excelCell.value = parseFloat(gridCell.value);
                        excelCell.numFmt = "£#,##0.00";
                    }
                    if (gridCell.value && gridCell.totalSummaryItemName == "clientBillableAmountAccrual") {
                        excelCell.value = parseFloat(gridCell.value);
                        excelCell.numFmt = "£#,##0.00";
                    }
                    if (gridCell.value && gridCell.totalSummaryItemName == "client_Billable_Amount_Local_Ccy") {
                        excelCell.value = parseFloat(gridCell.value);
                        excelCell.numFmt = "£#,##0.00";
                    }
                    if (gridCell.value && gridCell.totalSummaryItemName == "providerOriginalPayAmount") {
                        excelCell.value = parseFloat(gridCell.value);
                        excelCell.numFmt = "£#,##0.00";
                    }
                    if (gridCell.value && gridCell.totalSummaryItemName == "fee_Tax_Local_Ccy") {
                        excelCell.value = parseFloat(gridCell.value);
                        excelCell.numFmt = "£#,##0.00";
                    }
                    if (gridCell.value && gridCell.totalSummaryItemName == "fee_Total_Local_Ccy") {
                        excelCell.value = parseFloat(gridCell.value);
                        excelCell.numFmt = "£#,##0.00";
                    }
                    if (gridCell.value && gridCell.totalSummaryItemName == "client_Billable_Tax_Local_Ccy") {
                        excelCell.value = parseFloat(gridCell.value);
                        excelCell.numFmt = "£#,##0.00";
                    }
                    if (gridCell.value && gridCell.totalSummaryItemName == "client_Billable_Total_Local_Ccy") {
                        excelCell.value = parseFloat(gridCell.value);
                        excelCell.numFmt = "£#,##0.00";
                    }
                    if (gridCell.value && gridCell.totalSummaryItemName == "clientOriginalBillableAmount") {
                        excelCell.value = parseFloat(gridCell.value);
                        excelCell.numFmt = "£#,##0.00";
                    }

                }
                if (gridCell && gridCell.rowType === "data") {
                    if (gridCell.column && (gridCell.column.dataField === "rate_Decimal")){
                        excelCell.value = this.showStringToCurrencyFormat(gridCell.value);
                        excelCell.numFmt = "£#,##0.00";
                    }
                }
            },
        }).then(() => {
            workbook.xlsx.writeBuffer().then((buffer) => {
                saveAs(new Blob([buffer], { type: "application/octet-stream" }), "DataGrid.xlsx");
            });
        });
        e.cancel = true;
    };

    onCustomGridLoad = () => {
        if (this.props.navigateFrom !== GridTypes.managePayDatesUnpaidShiftGrid && this.props.navigateFrom !== GridTypes.addShiftBillingGrid) {
            return CookieService.loadDXGridConfiguration(DXGridCookieKeyTypes.eventUKManageShiftsGrid);
        }
    }

    onCustomGridSave = (gridState: Object) => {
        if (this.props.navigateFrom !== GridTypes.managePayDatesUnpaidShiftGrid && this.props.navigateFrom !== GridTypes.addShiftBillingGrid) {
            CookieService.saveDXGridConfiguration(DXGridCookieKeyTypes.eventUKManageShiftsGrid, gridState);
        }
    }


    renderDelEditTitleV2Header = () => {
        return UserService.isUserInGroup(RoleGroupNames.EventUKSeniorManager) ? (
            <>
                <div className="d-flex justify-content-between align-items-center">
                    <div className="mr-1">DELETE</div> <span>|</span>
                    {"  "} <div className="ml-1">MASTER EDIT</div>
                </div>
            </>
        ) : null;
    };


    onSelectionChanged = (value: DataGridOnSelectionChanged) => {
        if (value.selectedRowsData && value.selectedRowsData.length > 0) {
            if (this.props.navigateFrom !== GridTypes.addShiftBillingGrid) {
                value.selectedRowsData.forEach((items) => {
                    GlobalService.setBillableItemIds(items.id);
                });
            }
        }
        this.props.getSelectedRows ? this.props.getSelectedRows(value.selectedRowsData) : null;
    };

    billableHrsCellDisplayContent = (cellInfo: any) => {
        if (
            cellInfo.data.billable_Hours_Units &&
            cellInfo.data.billable_Hours_Units.includes("(") &&
            (cellInfo.data.itemTypeValue == "AdjustmentClientDebit" ||
                cellInfo.data.itemTypeValue == "AdjustmentProviderDebit")
        ) {
            var billedTime: string[] = this.costReportUtils.billableTimeDisplayContent(
                cellInfo.data ? cellInfo.data : "",
                "shiftmatrix"
            );
            let billableExtraHrsStr = cellInfo.data.billable_Hours_Units.substring(
                cellInfo.data.billable_Hours_Units.lastIndexOf("(") + 1,
                cellInfo.data.billable_Hours_Units.lastIndexOf(")")
            );
            let billableHrsStr = cellInfo.data.billable_Hours_Units.substr(
                0,
                cellInfo.data.billable_Hours_Units.indexOf("(") - 1
            );
            return (
                <div>
                    <span className={billedTime[0]}>{billableHrsStr}</span>
                    <span> ({billableExtraHrsStr})</span>
                </div>
            );
        } else {
            billedTime = this.costReportUtils.billableTimeDisplayContent(
                cellInfo.data ? cellInfo.data : "",
                "shiftmatrix"
            );
            return (
                <div>
                    <span className={billedTime[0]}>{`${billedTime[1]}`}</span>
                </div>
            );
        }
    };

    feedbackCellDisplayContent = (cellInfo: any) => {
        if (cellInfo.data.feedback && cellInfo.data.feedback.feedbackScore) {
            let feedbackScore: number = parseFloat(cellInfo.data.feedback.feedbackScore);
            let sign = Math.sign(feedbackScore);
            if(sign == -1){
                feedbackScore = 0;
            }
            return (
                <div className="artist-card__rating">
                    <StarRating score={feedbackScore} />
                </div>
            );
        }
    };

    showInfoFeedbackComments = (feedbackComments: string) => {
        this.setState({
            feedbackCommentsPopupVisible: true,
            feedbackComments: feedbackComments,
        });
    };

    hideInfoFeedbackComments = () => {
        this.setState({
            feedbackCommentsPopupVisible: false,
        });
    };

    feedbackCommentsCellDisplayContent = (cellInfo: any) => {
        return (
            <div>
                <Link
                    onClick={() => {
                        this.showInfoFeedbackComments(cellInfo?.data?.feedback?.feedbackComments);
                    }}
                    className="approval-query-column"
                    to="#"
                >
                    {cellInfo?.data?.feedback?.feedbackComments?.length > 16
                        ? `${cellInfo?.data?.feedback?.feedbackComments.substring(0, 16)}...`
                        : cellInfo?.data?.feedback?.feedbackComments}
                </Link>
            </div>
        );
    };

    onboardedCellDisplayContent = (cellInfo: any) => {
        let onboardedVal = cellInfo.data.data.venue?.onboarded;
        let isShowInColour: boolean = false;
        let valToShow: string = "";
        if (onboardedVal) {
            isShowInColour = true;
            valToShow = "Yes";
        }
        else {
            valToShow = "No";
        }
        return (
            <div className="container-fluid">
                {isShowInColour ? <span className="grid-info__text_color_green">{valToShow}</span> : valToShow}
            </div>
        );

    }

    billableRateCellDisplayContent = (cellInfo: any) => {
        let billableExtraRateStr = "";
        let billableRateStr = "";
        let billedRate: string[] = this.costReportUtils.billableRateDisplayContent(
            cellInfo.data ? cellInfo.data : "",
            "shiftmatrix"
        );
        if (cellInfo.data.rate_Decimal && cellInfo.data.rate_Decimal.includes("(")) {
            billableExtraRateStr = billedRate[1].substring(
                cellInfo.data.rate_Decimal.lastIndexOf("(") + 1,
                cellInfo.data.rate_Decimal.lastIndexOf(")")
            );
            billableExtraRateStr = "(" + " " + billableExtraRateStr + ")";
            billableRateStr = cellInfo.data.rate_Decimal.substr(0, billedRate[1].indexOf("("));
        }
        return (
            <div>
                {this.renderBillableRate(billableExtraRateStr, cellInfo.data.itemTypeId, billedRate, billableRateStr)}
            </div>
        );
    };

    internalCommentsCellDisplayContent = (cellInfo: any) => {
        return (
            <div>
                <Link
                    onClick={(e: MouseEvent) => {
                        e.preventDefault();
                        this.showInfoInternalComments(cellInfo.data.internalComments);
                    }}
                    className="approval-query-column"
                    to="#"
                >
                    {cellInfo.data.internalComments
                        ? cellInfo.data.internalComments.length > 16
                            ? `${cellInfo.data.internalComments.substring(0, 16)}...`
                            : cellInfo.data.internalComments
                        : ""}
                </Link>
            </div>
        );
    };

    providerNetCellDisplayContent = (cellInfo: any) => {
        return (
            <div>
                <Link
                    onClick={(e: MouseEvent) => {
                        e.preventDefault();
                        this.showNegativeShiftsPopup(cellInfo.data.providerBusinessEntity.id);
                    }}
                    className="approval-query-column"
                    to="#"
                >
                    {cellInfo.data.providerPayAmountLocal}
                </Link>
            </div>
        );
    };

    showInfoInternalComments = (internalComments: string) => {
        this.setState({
            internalCommentsPopupVisible: true,
            internalComments: internalComments,
        });
    };

    hideInfoInternalComments = () => {
        this.setState({
            internalCommentsPopupVisible: false,
        });
    };

    showNegativeShiftsPopup = (data: any) => {
        this.providerId = data;
        let dataSource = new CustomStore({
            key: "id",
            load: this.getResults,
        });
        this.setState({
            futureDataSource: dataSource,
            negativeShiftsPopupVisible: true,
            alignDate: this.props.payDate,
        });
        GlobalService.setPopupType(GridTypes.futureNegetiveShift);
    };

    hideNegativeShiftsPopup = () => {
        this.dataGrid.instance.clearSelection();
        this.setState({
            negativeShiftsPopupVisible: false,
            showContinuePopup: false,
            showErrorPopup: false,
        });
    };

    hideNegativeShiftsSubPopup = () => {
        this.setState({
            showContinuePopup: false,
            showErrorPopup: false,
        });
    };

    getResults = (loadOptions: any) => {
        let params = buildRequestParameters(loadOptions);
        let id = this.props.paymentId ? this.props.paymentId : "0";
        return this.simpleGridService.getFutureShiftGridRowsWithinRemoteOperations_ByProvider(params, id, this.providerId)
            .then(this.handleSuccessLoadDataSource)
            .catch(() => { throw 'Data Loading Error'; });
    }

    handleSuccessLoadDataSource = (response: any) => {
        this.setState({
            loadIndicatorForGrid: false
        });
        if ((response.data.data && response.data.data.billableItemRemoteGrid) || response.data.data.billableItemRemoteGrid == null) {
            this.setState({futureShiftsData:response.data.data.billableItemRemoteGrid});
            return {
                data: response.data.data.billableItemRemoteGrid ? response.data.data.billableItemRemoteGrid : [],
                totalCount: response.data.data.totalCount
            };

        }
        else {
            return {
                data: response.data.data ? response.data.data : [],
                totalCount: response.data.totalCount
            };
        }
    }

    renderBillableRate = (billableExtraRateStr: string, itemType: string, billedRate: string[], billableRateStr: string) => {
        if (billableExtraRateStr && itemType == itemTypes.Original) {
            return (
                <>
                    <span>{billableRateStr}</span> <span className={billedRate[0]}>{billableExtraRateStr}</span>
                </>
            );
        }
        else if (billableExtraRateStr && (itemType == itemTypes.AdjustmentClientDebit || itemType == itemTypes.AdjustmentProviderDebit || itemType == itemTypes.Adjustment)) {
            return (
                <>
                    <span className={billedRate[0]}>{billableRateStr}</span> <span>{billableExtraRateStr}</span>
                </>
            );
        }
        else {
            return (
                <ul className="list-style-type">
                    <li> {billedRate[1]} </li>
                </ul>
            );
        }
    }

    commentsCellDisplayContent = (cellInfo: any) => {
        return (
            <div>
                <Link
                    onClick={(e: MouseEvent) => {
                        e.preventDefault();
                        this.showInfoComments(cellInfo.data.reason);
                    }}
                    className="approval-query-column"
                    to="#"
                >
                    {cellInfo.data.reason
                        ? cellInfo.data.reason.length > 16
                            ? `${cellInfo.data.reason.substring(0, 16)}...`
                            : cellInfo.data.reason
                        : ""}
                </Link>
            </div>
        );
    };

    showInfoComments = (comments: string) => {
        this.setState({
            commentsPopupVisible: true,
            externalComments: comments,
        });
    };

    hideInfoComments = () => {
        this.setState({
            commentsPopupVisible: false,
        });
    };

    handlePopUpClose = (flag: boolean, editmode: string) => {
        this.isEditMode = editmode;
        this.setState({
            providerPopupVisibleEntertainment: flag,
        });
    }
    hideInfoProvider = (e: any) => {
        this.setState({
            providerPopupVisibleOther: false,
        });
    };

    showInfoProvider = (clientId: string, venueId: string, serviceTypeId: string, businessEntityId: string, service: string) => {
        this.setState({
            clientId: clientId,
            venueId: venueId,
            serviceTypeId: serviceTypeId,
            businessEntityId: businessEntityId,
            serviceName: service
        });
        if (serviceTypeId == "10") {            
            this.setState({
                providerPopupVisibleEntertainment: false,
                providerPopupVisibleOther: true,
            });
        }
        else {
            this.setState({
                providerPopupVisibleOther: false,
                providerPopupVisibleEntertainment: true,
            });
        }
    };

    providerCellDisplayContent = (cellInfo: any) => {
        return (
            <div>
                <Link
                    onClick={(e: MouseEvent) => {
                        e.preventDefault();
                        this.showInfoProvider(cellInfo.data.clientBusinessEntityId, cellInfo.data.venueId, cellInfo.data.serviceTypeLookUp.id, cellInfo.data.providerBusinessEntity.id, cellInfo.data.serviceTypeLookUp.value);
                    }}
                    className="approval-query-column"
                    to="#"
                >
                    {cellInfo.data.providerBusinessEntity?.shortName}
                </Link>
            </div>
        );
    };
    displayBooleanContent = (Val:any)=>{
        let valToShow: string = "";
        if (Val) {
            valToShow = "Yes";
        }
        else {
            valToShow = "No";
        }
        return (
            <div className="container-fluid">
                <span>{valToShow}</span>
            </div>
        );
    }

    hideInfoProviderArtistProfile = (e: any) => {
        e.cancel = true;
        if (this.isEditMode == "editMode") {
            confirm("<div class='text-center'>Close screen without saving?</div>", "Close").then((hasConfirmed) => {
                if (hasConfirmed) {
                    this.setState({
                        providerPopupVisibleEntertainment: false,
                    });
                    this.isEditMode = "";
                }
            });
        }
        else {
            this.setState({
                providerPopupVisibleEntertainment: false,
            });
        }
    };

    payReqCellDisplayContentOld = (cellInfo: any) => {
        return (
            <div>
                {cellInfo.data.paymentRequest?.fasterPayment.includes("Confirmed") ?
                    <span className="confirmed-query-column">{cellInfo.data.paymentRequest?.fasterPayment}</span>
                     : <Link
                        className="approval-query-column"
                        onClick={(e: MouseEvent) => {
                            e.preventDefault();
                            window.open(`/MatchedPopUpPage?filterBy=${cellInfo.data.paymentRequest?.id}`, "Related Payment Request/ Artist Job Notification",
                                "scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=1050,height=400,left=100,top=100")
                        }}
                        to="#"
                    >
                        {cellInfo.data.paymentRequest?.fasterPayment}
                    </Link>
                }
            </div>
        );
    };

    payReqCellDisplayContent = (cellInfo: any) => {
        let fasterPaymentVal: string = "";
        let confirmedPaymentVal: string = "";
        let paymentReqId: string = "";
        if (cellInfo && cellInfo.data && cellInfo.data.paymentRequest && cellInfo.data.paymentRequest.fasterPayment) {
            fasterPaymentVal = cellInfo.data.paymentRequest.fasterPayment;
            paymentReqId = cellInfo.data.paymentRequest.id;
        }
        else if (cellInfo && cellInfo.data && cellInfo.data.paymentRequest == null && cellInfo.data.fasterPayment !== "") {
            confirmedPaymentVal = cellInfo.data.fasterPayment;
        }
        
        return (
            <div>
                {fasterPaymentVal ?
                    <Link
                        className="approval-query-column"
                        onClick={(e: MouseEvent) => {
                            e.preventDefault();
                            window.open(`/MatchedPopUpPage?filterBy=${paymentReqId}`, "Related Payment Request/ Artist Job Notification",
                                "scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=1050,height=400,left=100,top=100")
                        }}
                        to="#"
                    >
                        {fasterPaymentVal}
                    </Link> : <span className="confirmed-query-column"
                    >{confirmedPaymentVal}</span>}
            </div>
        );
    };

    payReqCellValue = (cellInfo: any) => {
        let fasterPaymentVal: string = "";
        let confirmedPaymentVal: string = "";
        let paymentReqId: string = "";
        if (cellInfo && cellInfo.paymentRequest && cellInfo.paymentRequest.fasterPayment) {
            fasterPaymentVal = cellInfo.paymentRequest.fasterPayment;
            paymentReqId = cellInfo.paymentRequest.id;
        }
        else if (cellInfo && cellInfo.paymentRequest == null && cellInfo.fasterPayment !== "") {
            confirmedPaymentVal = cellInfo.fasterPayment;
        }
        
        return (
           fasterPaymentVal ? fasterPaymentVal : confirmedPaymentVal
        );
    };

    alignPayDates = () => {
        let selectedRowsData = this.dataGrid.instance.getSelectedRowsData();
        let Ids:any = [];  
        let cycleIds:any = [];    
        let totalAmount=0; 
        if(selectedRowsData.length > 0){
            selectedRowsData.forEach((items: any) => {                 
                Ids.push(items.id);
                cycleIds.push(items.paymentCycleId);
                totalAmount+=Number(items.providerPayAmountLocal);
            });
            this.setState({linkedId: Ids});  
            this.setState({paymentCycleIds: cycleIds});        
        }
        if(totalAmount == 0 || totalAmount > 0){
            this.setState({showContinuePopup: true});
        }
        else{
            this.setState({showErrorPopup: true}); 
        }
    }

    updatePayments = () => {
        this.setState({
            loadPanelVisible: false,
        });
        let id = this.props.paymentId ? this.props.paymentId : "0";
        if (this.userHasAccess && this.state.paymentCycleIds.includes(id)) { 
            this.setState({
                loadPanelVisible: true,
            });   
            this.paymentService
                .generatePaymentsV2(id)
                .then(this.handlePostSuccess)
                .catch(this.handlePostFailure);
        }
    };

    handlePostSuccess = (response: AxiosResponse<any>) => {
        this.setState(
            {
                loadPanelVisible: false,
            });
        this.props?.updateClicked ? this.props.updateClicked(true) : false;
    };

    handlePostFailure = (error: AxiosError<any>) => {
        this.setState({
            loadPanelVisible: false,
        });
    };

    onProceed = () => {   
        let date = moment(this.state.alignDate).format("DD/MM/YYYY");
        this.setState({
            loadPanelVisible: true,
        });     
        this.hideNegativeShiftsPopup(),
        this.hideNegativeShiftsSubPopup(),
        this.simpleGridService.submitApproveShiftWithPayDate(date, this.state.linkedId)
            .then((response) =>
                this.updatePayments(),
            )
            .catch((error) =>
                console.log(error)
            );
    };

    handleChangeAlignDate = (dxValueChange: DateBoxOnValueChangedEvent) => {
        this.setState({ alignDate: dxValueChange.value });
    };

    isRefresh = (flag:boolean) =>{
        this.gridRef?.instance?.clearSelection();
        this.props.onRefresh ? this.props.onRefresh(flag) : null;
    }

    approveActionCell = (cellInfo: any) => {
        return(
            <div>
                <ApproveActionCell data={cellInfo} onRefresh={this.isRefresh} i18n={cellInfo} tReady={false} t={cellInfo} />
            </div>        
        );
    }

    headerOfSelectedCell = () => {
        return <div>SELECT</div>;
    };

    render() {
        let modeOfSelection: string = "";
        if (this.props.navigateFrom == GridTypes.billing || this.props.navigateFrom == GridTypes.addShiftBillingGrid) {
            modeOfSelection = "multiple";
        }
        if (this.props.navigateFrom == GridTypes.futureNegetiveShift) {
            GlobalService.setGridType(GridTypes.futureNegetiveShift);
        }
        if (this.props.navigateFrom == "artistProfile") {
            GlobalService.setGridType(GridTypes.artistShiftMatrix);
        }
        if (this.props.navigateFrom == "providerProfile") {
            GlobalService.setGridType(GridTypes.providerShiftMatrix);
        }
        const isEventUk =
            UserService.isUserInGroup(RoleGroupNames.EventUKRelationshipManager) ||
            UserService.isUserInGroup(RoleGroupNames.EventUKSeniorManager);
        return (

            <div>
                <DataGrid
                    elementAttr={{
                        id: "gridContainer"
                    }}
                    dataSource={this.props.dataSource}
                    showBorders={false}
                    showColumnLines={false}
                    allowColumnReordering={true}
                    allowColumnResizing={true}
                    columnAutoWidth={true}
                    columnResizingMode={"widget"}
                    hoverStateEnabled={true}
                    wordWrapEnabled={false}
                    remoteOperations={this.remoteOperations}
                    noDataText={"No Data Found"}
                    onExporting={this.onExporting}
                    onSelectionChanged={this.onSelectionChanged}
                    columnMinWidth={100}
                    ref={(ref) => { this.gridRef = ref; }}
                >

{
                        this.props.navigateFrom == GridTypes.managePayDatesUnpaidShiftGrid || this.props.navigateFrom == GridTypes.addShiftBillingGrid ?
                                <StateStoring enabled={false}></StateStoring>
                                : <StateStoring
                                enabled={true}
                                type="custom"
                                customLoad={this.onCustomGridLoad}
                                customSave={this.onCustomGridSave}
                            />
                    }
                    
                    <Selection mode={modeOfSelection}></Selection>
                    {
                        this.props.navigateFrom == GridTypes.managePayDatesUnpaidShiftGrid ?
                                <Paging defaultPageSize={this.props.defaultPageSize} enabled={false}/>
                                : <Paging defaultPageSize={5} />
                    }
                    {
                        this.props.navigateFrom == GridTypes.managePayDatesUnpaidShiftGrid ?
                        <Pager showPageSizeSelector={false}  visible={false}/>
                                : <Pager showPageSizeSelector={true} allowedPageSizes={[5, 10, 20]} showInfo={true} />
                    }
                    {/* <Paging defaultPageSize={5} />
                    <Pager showPageSizeSelector={true} allowedPageSizes={[5, 10, 20]} showInfo={true} /> */}
                    <HeaderFilter visible={true} allowSearch={true} />
                    <Export enabled={true} />
                    <FilterRow visible={true} applyFilter="auto" />
                    <GroupPanel visible={true} />
                    <FilterPanel visible={true} />
                    <SearchPanel visible={false} placeholder={"Search"} />
                    <Scrolling useNative={true} showScrollbar={"always"} />
                    <Column
                        caption="APPROVE"
                        //cellComponent={ApproveActionCell}
                        cellRender={this.approveActionCell}
                        allowExporting={false}
                    />

                    <Column
                        caption="QUERY"
                        cellComponent={QueryActionCell}
                        allowExporting={false}
                    />
                    <Column
                        caption="CHANGE"
                        cellComponent={ChangeShiftMatrixCell}
                        allowExporting={false}
                    />
                    <Column caption="VIEW" cellComponent={ViewShiftMatrixCell} allowExporting={false} />
                    <Column
                        dataField="billableStatusLookUp.value"
                        caption="STATUS"
                    />
                    <Column 
                        dataField="paymentRequest.fasterPayment" 
                        caption="PAY REQUEST" 
                        cellRender={this.payReqCellDisplayContent} 
                        calculateCellValue={this.payReqCellValue}
                        allowGrouping={false}
                        allowFiltering={false}
                        allowSearch={false}
                        allowSorting={false}
                    />
                    <Column
                        dataField="dateFrom"
                        caption="DATE"
                        format="dd/MM/yyyy"
                        dataType="date"
                    />
                    <Column
                        dataField="approvalDate"
                        caption="APPROVAL DATE"
                        format="dd/MM/yyyy"
                        dataType="date"
                    />

                    <Column
                        dataField="shiftCompleteAcknowledgmentDateTime"  
                        caption="SHIFT ACKNOWLEDGEMENT DATE"
                        format="dd/MM/yyyy"
                        dataType="date"
                    />
                    <Column
                        dataField="clientBusinessEntity.shortName"
                        caption="CLIENT"
                    />
                    <Column
                        dataField="providerBusinessEntity.shortName"
                        caption="PROVIDER/ARTIST"
                        cellRender={this.providerCellDisplayContent}
                    />
                    <Column
                        dataField="providerBusinessEntity.id"
                        caption="PROVIDER ID"
                    />
                    <Column
                        dataField="providerBusinessEntity.professionalName"
                        caption="ACT NAME"
                    />
                   
                    <Column
                        dataField="providerBusinessEntity.verified"
                        caption="BUSINESS SET-UP VERIFIED"
                        cellComponent={(cellInfo: any) => { return this.displayBooleanContent(cellInfo?.data?.data?.providerBusinessEntity?.verified) }}
                        dataType="boolean"
                    />
                    <Column
                        dataField="providerBusinessEntity.vatVerified"
                        caption="VAT VERIFIED"
                        cellComponent={(cellInfo: any) => { return this.displayBooleanContent(cellInfo?.data?.data?.providerBusinessEntity?.vatVerified) }}
                        dataType="boolean"
                    />
                    <Column
                        dataField="bankAccountVerified"
                        caption="BANK ACCOUNT VERIFIED"
                        cellComponent={(cellInfo: any) => { return this.displayBooleanContent(cellInfo?.data?.data?.bankAccountVerified) }}
                        // dataType="boolean"
                        allowFiltering={false}
                        allowSearch={false}
                        allowSorting={false}
                    />
                            
                    <Column
                        dataField="feedback.feedbackScore"
                        caption="FEEDBACK"
                        cellRender={this.feedbackCellDisplayContent}
                        allowGrouping={false}
                        allowFiltering={false}
                        allowSearch={false}
                        allowSorting={false}
                    />
                    <Column
                        dataField="feedback.feedbackComments"
                        caption="FEEDBACK COMMENTS"
                        cellRender={this.feedbackCommentsCellDisplayContent}
                        allowGrouping={false}
                        allowFiltering={false}
                        allowSearch={false}
                        allowSorting={false}
                    />
                    <Column
                        dataField="venue.venueName"
                        caption="VENUE"
                    />
                    <Column
                        dataField="venue.houseNumber"
                        caption="VENUE NO"
                    />
                    <Column 
                        dataField="venue.onboarded" 
                        caption="ONBOARDED" 
                        cellComponent={this.onboardedCellDisplayContent}
                    />
                    <Column
                        dataField="serviceTypeLookUp.value"
                        caption="SERVICE"
                        cellComponent={AllUserGridService}
                    />
                    <Column
                        caption="SUB-TYPE"
                        dataField="serviceSubTypeLookUp.value"
                    />
                    <Column
                        caption="ROLE/TYPE"
                        dataField="typeLookUp.value"
                    />
                    <Column
                        dataField="name"
                        caption="NAME"
                    />
                    <Column
                        dataField="shift"
                        caption="SCHEDULED SHIFT"
                        allowGrouping={false}
                        allowFiltering={false}
                        allowSearch={false}
                        allowSorting={false}
                        cellComponent={AllUserGridShift}
                    />
                    <Column
                        dataField="performanceInformation"
                        caption="PERFORMANCE DETAILS"
                        allowGrouping={false}
                        allowFiltering={false}
                        allowSearch={false}
                        allowSorting={false}
                    />
                    <Column
                        dataField="billable_Hours_Units"
                        caption="TIME/QUANTITY"
                        cellRender={this.billableHrsCellDisplayContent}
                        allowGrouping={false}
                        allowFiltering={false}
                        allowSearch={false}
                        allowSorting={false}
                    />
                    <Column
                        dataField="timeDecimal"
                        caption="TIME(DECIMAL)"
                        allowGrouping={false}
                        allowFiltering={false}
                        allowSearch={false}
                        allowSorting={false}
                    />
                    <Column
                        dataField="rate_Decimal"
                        caption="RATE/FEE" 
                        cellRender={this.billableRateCellDisplayContent}
                        allowGrouping={false}
                        allowFiltering={false}
                        allowSearch={false}
                        allowSorting={false}
                    />
                    <Column 
                        dataField="cost" 
                        caption="COST"
                        allowGrouping={false}
                        allowFiltering={false}
                        allowSearch={false}
                        allowSorting={false} 
                    />
                    <Column
                        dataField="reason"
                        caption="COMMENTS" cellRender={this.commentsCellDisplayContent} />
                    <Column
                        dataField="internalComments"
                        caption="INTERNAL COMMENTS"
                        cellRender={this.internalCommentsCellDisplayContent}
                    />
                    <Column
                        dataField="billableItemType.value"
                        caption="ITEM TYPE"
                    />
                    <Column dataField="hardApproval" caption="HARD APPROVAL" />
                    <Column dataField="holdPayment" caption="HOLD PAYMENT" />
                    { this.props.navigateFrom == GridTypes.futureNegetiveShift ? 
                        <Column
                            dataField="providerPayAmountLocal"
                            caption="PROVIDER NET"
                            dataType="number"
                            cellRender={this.providerNetCellDisplayContent}
                        >
                            <Format type="currency" currency='GBP' precision={2}></Format>
                        </Column> : 
                        <Column
                            dataField="providerPayAmountLocal"
                            caption="PROVIDER NET"
                            dataType="number"
                        >
                            <Format type="currency" currency='GBP' precision={2}></Format>
                        </Column>                        
                    }  

                    <Column
                        dataField="providerPayAmountTaxLocal"
                        caption="PROVIDER VAT"
                        dataType="number"
                    >
                        <Format type="currency" currency='GBP' precision={2}></Format>
                    </Column>

                    <Column
                        dataField="providerPayAmountTotalLocal"
                        caption="PROVIDER GROSS"
                        dataType="number"
                    >
                        <Format type="currency" currency='GBP' precision={2}></Format>
                    </Column>

                    <Column
                        dataField="fee_Amount_Local_Ccy"
                        caption="EVENT FEE NET"
                        allowGrouping={false}
                        allowFiltering={false}
                        allowSearch={false}
                        allowSorting={false}
                        dataType="number"
                    >
                        <Format type="currency" currency='GBP' precision={2}></Format>
                    </Column>

                    <Column
                        dataField="providerPayAmountAccrual"
                        caption="PAY ACCRUAL"
                        allowGrouping={false}
                        allowFiltering={false}
                        allowSearch={false}
                        allowSorting={false}
                        dataType="number"
                    >
                        <Format type="currency" currency='GBP' precision={2}></Format>
                    </Column>
                    <Column
                        dataField="grossProfitAmountAccrual"
                        caption="FEE ACCRUAL"
                        allowGrouping={false}
                        allowFiltering={false}
                        allowSearch={false}
                        allowSorting={false}
                        dataType="number"
                    >
                        <Format type="currency" currency='GBP' precision={2}></Format>
                    </Column>
                    <Column
                        dataField="clientBillableAmountAccrual"
                        caption="CLIENT ACCRUAL"
                        allowGrouping={false}
                        allowFiltering={false}
                        allowSearch={false}
                        allowSorting={false}
                        dataType="number"
                    >
                        <Format type="currency" currency='GBP' precision={2}></Format>
                    </Column>
                    <Column
                        dataField="client_Billable_Amount_Local_Ccy"
                        caption="TOTAL NET"
                        allowGrouping={false}
                        allowFiltering={false}
                        allowSearch={false}
                        allowSorting={false}
                        dataType="number"
                    >
                        <Format type="currency" currency='GBP' precision={2}></Format>
                    </Column>
                    <Column
                        dataField="idValue"
                        caption="IDENTIFICATION NO."
                    />
                    <Column dataField="cover" caption="COVER" />
                    <Column 
                        dataField="billable_Start_Finish" 
                        caption="BILLABLE START/FINISH" 
                        allowGrouping={false}
                        allowFiltering={false}
                        allowSearch={false}
                        allowSorting={false} 
                    />
                    <Column
                        dataField="dateTo"
                        caption="DATE TO"
                        format="dd/MM/yyyy"
                        dataType="date" /> 
                    <Column
                        dataField="payDate"
                        caption="PAY DATE"
                        format="dd/MM/yyyy"
                        dataType="date" />
                    <Column
                        dataField="billDate"
                        caption="BILL DATE"
                        format="dd/MM/yyyy"
                        dataType="date" />   
                    <Column
                        dataField="dateCreated"
                        caption="DATE CREATED"
                        format="dd/MM/yyyy"
                        dataType="date" />  
                    <Column
                        dataField="clientAmendDateTime"
                        caption="CLIENT AMEND DATE"
                        format="dd/MM/yyyy"
                        dataType="date" 
                    />
                    <Column
                        dataField="payment.paymentStatusLookUp.value"
                        caption="PAYMENT STATUS"/>
                    <Column
                        dataField="providerTaxApplicable"
                        caption="PROVIDER VAT APPLICABLE?"
                        allowGrouping={false}
                        allowFiltering={false}
                        allowSearch={false}
                        allowSorting={false}
                    />
                    <Column
                        dataField="clientTaxApplicable"
                        caption="CLIENT VAT APPLICABLE?"
                        allowGrouping={false}
                        allowFiltering={false}
                        allowSearch={false}
                        allowSorting={false}
                    />
                    <Column
                        dataField="providerPayAmountLocalCurrency"
                        caption="PROVIDER PAY CURRENCY"
                    />
                    <Column
                        dataField="providerOriginalPayAmount"
                        caption="PROVIDER PAY ORIGINAL AMOUNT"
                        dataType="number"
                    >
                        <Format type="currency" currency='GBP' precision={2}></Format>
                    </Column>
                    <Column
                        dataField="grossProfitAmountLocalCurrency"
                        caption="FEE CURRENCY"
                    />
                    <Column
                        dataField="fee_Tax_Local_Ccy"
                        caption="FEE VAT"
                        allowGrouping={false}
                        allowFiltering={false}
                        allowSearch={false}
                        allowSorting={false}
                        dataType="number"
                    >
                        <Format type="currency" currency='GBP' precision={2}></Format>
                    </Column>
                    <Column
                        dataField="fee_Total_Local_Ccy"
                        caption="FEE TOTAL"
                        allowGrouping={false}
                        allowFiltering={false}
                        allowSearch={false}
                        allowSorting={false}
                        dataType="number"
                    >
                        <Format type="currency" currency='GBP' precision={2}></Format>
                    </Column>
                    <Column dataField="grossProfitPercentageLocal" caption="FEE %" />
                    <Column
                        dataField="clientBillableAmountLocalCurrency"
                        caption="CLIENT BILLABLE CURRENCY" />
                    <Column
                        dataField="client_Billable_Tax_Local_Ccy"
                        caption="CLIENT BILLABLE VAT" 
                        allowGrouping={false}
                        allowFiltering={false}
                        allowSearch={false}
                        allowSorting={false} 
                        dataType="number"
                    >
                        <Format type="currency" currency='GBP' precision={2}></Format>
                    </Column>
                    <Column
                        dataField="client_Billable_Total_Local_Ccy"
                        caption="CLIENT BILLABLE TOTAL" 
                        allowGrouping={false}
                        allowFiltering={false}
                        allowSearch={false}
                        allowSorting={false}
                        dataType="number"
                    >
                        <Format type="currency" currency='GBP' precision={2}></Format>
                    </Column>
                    <Column
                        dataField="clientOriginalBillableAmount"
                        caption="CLIENT BILLABLE ORIGINAL AMOUNT"
                        dataType="number"
                    >
                        <Format type="currency" currency='GBP' precision={2}></Format>
                    </Column>

                    <Column
                        dataField="acceptDate"
                        caption="ACCEPT DATE"
                        format="dd/MM/yyyy"
                        dataType="date" />
                    <Column
                        dataField="approvalDate"
                        caption="APPROVAL DATE"
                        format="dd/MM/yyyy"
                        dataType="date" />
                    {isEventUk && <Column dataField="sortCode" caption="SORT CODE" />}
                    {isEventUk && <Column dataField="accountNumber" caption="ACCOUNT NUMBER" />}
                    <Column
                        dataField="nonStandard"
                        caption="NON STANDARD" />
                    <Column 
                        dataField="batchId" 
                        caption="BATCH" 
                        allowGrouping={false}
                        allowFiltering={false}
                        allowSearch={false}
                        allowSorting={false} 
                    />
                    <Column dataField="id" caption="ID" />
                    {UserService.isUserInGroup(RoleGroupNames.EventUKSeniorManager) ?
                        <Column
                            headerCellRender={this.renderDelEditTitleV2Header}
                            cellComponent={DeleteEditShiftActionCellV2}
                            allowGrouping={false}
                            allowFiltering={false}
                            allowSearch={false}
                            allowSorting={false}
                        /> : null}
                    <Column dataField='areaManager' caption='Area Manager'
                        allowFiltering={false}
                        allowSearch={false}
                        allowSorting={false} />
                </DataGrid>
                <LoadPanel shadingColor="rgba(0,0,0,0.4)" visible={this.state.loadPanelVisible} />
                <Popup
                    visible={this.state.feedbackCommentsPopupVisible}
                    onHiding={this.hideInfoFeedbackComments}
                    dragEnabled={false}
                    closeOnOutsideClick={true}
                    showTitle={true}
                    showCloseButton={true}
                    title={"FEEDBACK COMMENTS"}
                    resizeEnabled={true}
                    width="550px"
                    height="auto"
                >
                    <ScrollView width="100%" height="100%">
                        <div>
                            <div className="card">
                                <div className="card-body">
                                    <p>{this.state.feedbackComments}</p>
                                </div>
                            </div>
                        </div>
                    </ScrollView>
                </Popup>
                <Popup
                    visible={this.state.internalCommentsPopupVisible}
                    onHiding={this.hideInfoInternalComments}
                    dragEnabled={false}
                    closeOnOutsideClick={true}
                    showTitle={true}
                    showCloseButton={true}
                    title={"INTERNAL COMMENTS"}
                    resizeEnabled={true}
                    width="550px"
                    height="auto"
                >
                    <ScrollView width="100%" height="100%">
                        <div>
                            <div className="card">
                                <div className="card-body">
                                    <p>{this.state.internalComments}</p>
                                </div>
                            </div>
                        </div>
                    </ScrollView>
                </Popup>
                <Popup
                    visible={this.state.commentsPopupVisible}
                    onHiding={this.hideInfoComments}
                    dragEnabled={false}
                    closeOnOutsideClick={true}
                    showTitle={true}
                    showCloseButton={true}
                    title={"COMMENTS"}
                    resizeEnabled={true}
                    width="550px"
                    height="auto"
                >
                    <ScrollView width="100%" height="100%">
                        <div>
                            <div className="card">
                                <div className="card-body">
                                    <p>{this.state.externalComments}</p>
                                </div>
                            </div>
                        </div>
                    </ScrollView>
                </Popup>
                <Popup
                    visible={this.state.providerPopupVisibleOther}
                    onHiding={this.hideInfoProvider}
                    dragEnabled={false}
                    closeOnOutsideClick={true}
                    showTitle={true}
                    showCloseButton={true}
                    title="Supplier Information & Venue Conditions"
                    resizeEnabled={true}
                >
                    <ScrollView width="100%" height="100%">
                        <SupplierInformationMainContent
                            clientId={this.state.clientId}
                            venueId={this.state.venueId}
                            serviceTypeId={this.state.serviceTypeId}
                            navigationFromShift="SimpleShiftGrid"
                        ></SupplierInformationMainContent>
                    </ScrollView>
                </Popup>
                {
                    this.state.providerPopupVisibleEntertainment ? (
                        <Popup
                            visible={this.state.providerPopupVisibleEntertainment}
                            onHiding={this.hideInfoProviderArtistProfile}
                            dragEnabled={false}
                            closeOnOutsideClick={true}
                            showTitle={true}
                            showCloseButton={true}
                            title={this.state.serviceTypeId == "30" ? "Artist Details" : "Provider Details"}
                            resizeEnabled={true}
                        >
                            <ScrollView width="100%" height="100%">
                                <ArtistProfileComponent
                                    mode="view"
                                    id={this.state.businessEntityId}
                                    handlePopUpClose={this.handlePopUpClose}
                                    businessEntityId={this.state.businessEntityId}
                                    handleFeedbackUpdate={() => { return null }}
                                    handleAddArtist={() => { return null }}
                                    serviceType={this.state.serviceName}
                                ></ArtistProfileComponent>
                            </ScrollView>
                        </Popup>
                    ) : (null)
                }
                
                <Popup
                    visible={this.state.showContinuePopup}
                    onHiding={this.hideNegativeShiftsSubPopup}
                    dragEnabled={false}
                    closeOnOutsideClick={true}
                    showTitle={true}
                    showCloseButton={true}
                    title={"CONFIRMATION: Payments Will Be Re-Generated"}
                    resizeEnabled={true}
                    width="550px"
                    height="auto"
                >
                    <ScrollView width="100%" height="100%">
                        <div>
                            <div className="text-center">This update has updated Pay Dates within this cycle and payments need to be re-generated - continue?</div>
                        </div>
                        <div className="row px-3">
                            <div className="col-12 col-xl-6 pl-0">
                                <button
                                    className="btn btn-primary btn--large mt-3"
                                    type="submit"
                                    onClick={(e: MouseEvent) => {
                                        e.preventDefault();
                                        this.onProceed();
                                    }}
                                >
                                    CONTINUE?
                                </button>
                            </div>
                            <div className="col-12 col-xl-6 pl-0">
                                <button
                                    className="btn btn--ghost btn--ghost--teal btn--large mt-3"
                                    onClick={(e: MouseEvent) => {
                                        e.preventDefault();
                                        this.hideNegativeShiftsSubPopup();
                                    }}
                                >
                                    CLOSE
                                </button>
                            </div>
                        </div> 
                    </ScrollView>
                </Popup>
                
                <Popup
                    visible={this.state.showErrorPopup}
                    onHiding={this.hideNegativeShiftsSubPopup}
                    dragEnabled={false}
                    closeOnOutsideClick={true}
                    showTitle={true}
                    showCloseButton={true}
                    title={"ERROR"}
                    resizeEnabled={true}
                    width="550px"
                    height="auto"
                >
                    <ScrollView width="100%" height="100%">
                        <div>
                            <div className="card">
                                <div className="card-body">
                                    <p>Total Payment Must Be Zero or Positive</p>
                                </div>
                            </div>
                        </div>
                    </ScrollView>
                </Popup>
                <Popup
                    visible={this.state.negativeShiftsPopupVisible}
                    onHiding={this.hideNegativeShiftsPopup}
                    dragEnabled={false}
                    closeOnOutsideClick={true}
                    showTitle={true}
                    showCloseButton={true}
                    title={"INCLUDED SHIFTS"}
                    resizeEnabled={true}
                    width={"90%"}
                    height={600}
                >
                    <ScrollView width="100%" height="100%">
                        <div>
                            <div className="card">
                                <div className="card-body">
                                    <div className="row mt-3">
                                        <div className="col-12 col-lg-2 mb-3">
                                            <button
                                                className="btn btn-primary btn--large mt-3"
                                                type="button"
                                                onClick={(e: MouseEvent) => {
                                                    e.preventDefault();
                                                    this.alignPayDates();
                                                }}
                                            >
                                                ALIGN PAY DATES
                                            </button>
                                        </div>
                                        <div className="col-12 col-lg-2 mb-3 mt-3">
                                        <DateBox
                                            displayFormat="dd/MM/yyyy"
                                            onValueChanged={this.handleChangeAlignDate}
                                            value={
                                                this.state.alignDate
                                                    ? this.state.alignDate
                                                    : undefined
                                            }
                                            useMaskBehavior={true}
                                        >
                                        </DateBox>
                                        </div>
                                    </div>
                                    <DataGrid
                                        elementAttr={{
                                            id: "gridContainer"
                                        }}
                                        dataSource={this.state.futureDataSource}
                                        showBorders={false}
                                        showColumnLines={false}
                                        allowColumnReordering={true}
                                        allowColumnResizing={true}
                                        columnAutoWidth={true}
                                        columnResizingMode={"widget"}
                                        hoverStateEnabled={true}
                                        wordWrapEnabled={false}
                                        remoteOperations={this.remoteOperations}
                                        noDataText={"No Data Found"}
                                        onExporting={this.onExporting}
                                        onSelectionChanged={this.onSelectionChanged}
                                        columnMinWidth={100}
                                        ref={(ref) => { this.dataGrid = ref; }}
                                    >
                                        <StateStoring
                                            enabled={true}
                                            type="custom"
                                            customLoad={this.onCustomGridLoad}
                                            customSave={this.onCustomGridSave}
                                        />                                        
                                        <Paging defaultPageSize={10} />
                                        <Selection mode="multiple" selectAllMode="allPages" showCheckBoxesMode="always"></Selection>
                                        <Pager showPageSizeSelector={true} allowedPageSizes={[5, 10, 20]} showInfo={true} />
                                        <HeaderFilter visible={true} allowSearch={true} />
                                        <Export enabled={true} />
                                        <FilterRow visible={true} applyFilter="auto" />
                                        <GroupPanel visible={true} />
                                        <FilterPanel visible={true} />
                                        <SearchPanel visible={false} placeholder={"Search"} />
                                        <Scrolling useNative={true} showScrollbar={"always"} />
                                        <Column headerCellRender={this.headerOfSelectedCell} type="selection"/>   
                                        <Column
                                            caption="APPROVE"
                                            cellRender={this.approveActionCell}
                                            allowExporting={false}
                                        />

                                        <Column
                                            caption="QUERY"
                                            cellComponent={QueryActionCell}
                                            allowExporting={false}
                                        />
                                        <Column
                                            caption="CHANGE"
                                            cellComponent={ChangeShiftMatrixCell}
                                            allowExporting={false}
                                        />
                                        <Column caption="VIEW" cellComponent={ViewShiftMatrixCell} allowExporting={false} />
                                        <Column
                                            dataField="billableStatusLookUp.value"
                                            caption="STATUS"
                                        />
                                        <Column 
                                            dataField="paymentRequest.fasterPayment" 
                                            caption="PAY REQUEST" 
                                            cellRender={this.payReqCellDisplayContent} 
                                            calculateCellValue={this.payReqCellValue}
                                            allowGrouping={false}
                                            allowFiltering={false}
                                            allowSearch={false}
                                            allowSorting={false}
                                        />
                                        <Column
                                            dataField="dateFrom"
                                            caption="DATE"
                                            format="dd/MM/yyyy"
                                            dataType="date"
                                        />
                                        <Column
                                            dataField="approvalDate"
                                            caption="APPROVAL DATE"
                                            format="dd/MM/yyyy"
                                            dataType="date"
                                        />

                                        <Column
                                            dataField="shiftCompleteAcknowledgmentDateTime"
                                            caption="SHIFT ACKNOWLEDGEMENT DATE"
                                            format="dd/MM/yyyy"
                                            dataType="date"
                                        />
                                        <Column
                                            dataField="clientBusinessEntity.shortName"
                                            caption="CLIENT"
                                        />
                                        <Column
                                            dataField="providerBusinessEntity.shortName"
                                            caption="PROVIDER/ARTIST"
                                            cellRender={this.providerCellDisplayContent}
                                        />
                                        <Column
                                            dataField="providerBusinessEntity.id"
                                            caption="PROVIDER ID"
                                        />
                                        <Column
                                            dataField="providerBusinessEntity.professionalName"
                                            caption="ACT NAME"
                                        />
                                        <Column
                                            dataField="feedback.feedbackScore"
                                            caption="FEEDBACK"
                                            cellRender={this.feedbackCellDisplayContent}
                                            allowGrouping={false}
                                            allowFiltering={false}
                                            allowSearch={false}
                                            allowSorting={false}
                                        />
                                        <Column
                                            dataField="feedback.feedbackComments"
                                            caption="FEEDBACK COMMENTS"
                                            cellRender={this.feedbackCommentsCellDisplayContent}
                                            allowGrouping={false}
                                            allowFiltering={false}
                                            allowSearch={false}
                                            allowSorting={false}
                                        />
                                        <Column
                                            dataField="venue.venueName"
                                            caption="VENUE"
                                        />
                                        <Column
                                            dataField="venue.houseNumber"
                                            caption="VENUE NO"
                                        />
                                        <Column 
                                            dataField="venue.onboarded" 
                                            caption="ONBOARDED" 
                                            cellComponent={this.onboardedCellDisplayContent}
                                        />
                                        <Column
                                            dataField="serviceTypeLookUp.value"
                                            caption="SERVICE"
                                            cellComponent={AllUserGridService}
                                        />
                                        <Column
                                            caption="SUB-TYPE"
                                            dataField="serviceSubTypeLookUp.value"
                                        />
                                        <Column
                                            caption="ROLE/TYPE"
                                            dataField="typeLookUp.value"
                                        />
                                        <Column
                                            dataField="name"
                                            caption="NAME"
                                        />
                                        <Column
                                            dataField="shift"
                                            caption="SCHEDULED SHIFT"
                                            allowGrouping={false}
                                            allowFiltering={false}
                                            allowSearch={false}
                                            allowSorting={false}
                                            cellComponent={AllUserGridShift}
                                        />
                                        <Column
                                            dataField="performanceInformation"
                                            caption="PERFORMANCE DETAILS"
                                            allowGrouping={false}
                                            allowFiltering={false}
                                            allowSearch={false}
                                            allowSorting={false}
                                        />
                                        <Column
                                            dataField="billable_Hours_Units"
                                            caption="TIME/QUANTITY"
                                            cellRender={this.billableHrsCellDisplayContent}
                                            allowGrouping={false}
                                            allowFiltering={false}
                                            allowSearch={false}
                                            allowSorting={false}
                                        />
                                        <Column
                                            dataField="timeDecimal"
                                            caption="TIME(DECIMAL)"
                                            allowGrouping={false}
                                            allowFiltering={false}
                                            allowSearch={false}
                                            allowSorting={false}
                                        />
                                        <Column
                                            dataField="rate_Decimal"
                                            caption="RATE/FEE" 
                                            cellRender={this.billableRateCellDisplayContent}
                                            allowGrouping={false}
                                            allowFiltering={false}
                                            allowSearch={false}
                                            allowSorting={false}
                                        />
                                        <Column 
                                            dataField="cost" 
                                            caption="COST"
                                            allowGrouping={false}
                                            allowFiltering={false}
                                            allowSearch={false}
                                            allowSorting={false} 
                                        />
                                        <Column
                                            dataField="reason"
                                            caption="COMMENTS" cellRender={this.commentsCellDisplayContent} />
                                        <Column
                                            dataField="internalComments"
                                            caption="INTERNAL COMMENTS"
                                            cellRender={this.internalCommentsCellDisplayContent}
                                        />
                                        <Column
                                            dataField="billableItemType.value"
                                            caption="ITEM TYPE"
                                        />
                                        <Column dataField="hardApproval" caption="HARD APPROVAL" />
                                        <Column dataField="holdPayment" caption="HOLD PAYMENT" />
                                        <Column
                                            dataField="providerPayAmountLocal"
                                            caption="PROVIDER NET"
                                            dataType="number"
                                        >
                                            <Format type="currency" currency='GBP' precision={2}></Format>
                                        </Column>  
                                        <Column
                                            dataField="providerPayAmountTaxLocal"
                                            caption="PROVIDER VAT"
                                            dataType="number"
                                        >
                                            <Format type="currency" currency='GBP' precision={2}></Format>
                                        </Column>

                                        <Column
                                            dataField="providerPayAmountTotalLocal"
                                            caption="PROVIDER GROSS"
                                            dataType="number"
                                        >
                                            <Format type="currency" currency='GBP' precision={2}></Format>
                                        </Column>

                                        <Column
                                            dataField="fee_Amount_Local_Ccy"
                                            caption="EVENT FEE NET"
                                            allowGrouping={false}
                                            allowFiltering={false}
                                            allowSearch={false}
                                            allowSorting={false}
                                            dataType="number"
                                        >
                                            <Format type="currency" currency='GBP' precision={2}></Format>
                                        </Column>

                                        <Column
                                            dataField="providerPayAmountAccrual"
                                            caption="PAY ACCRUAL"
                                            allowGrouping={false}
                                            allowFiltering={false}
                                            allowSearch={false}
                                            allowSorting={false}
                                            dataType="number"
                                        >
                                            <Format type="currency" currency='GBP' precision={2}></Format>
                                        </Column>
                                        <Column
                                            dataField="grossProfitAmountAccrual"
                                            caption="FEE ACCRUAL"
                                            allowGrouping={false}
                                            allowFiltering={false}
                                            allowSearch={false}
                                            allowSorting={false}
                                            dataType="number"
                                        >
                                            <Format type="currency" currency='GBP' precision={2}></Format>
                                        </Column>
                                        <Column
                                            dataField="clientBillableAmountAccrual"
                                            caption="CLIENT ACCRUAL"
                                            allowGrouping={false}
                                            allowFiltering={false}
                                            allowSearch={false}
                                            allowSorting={false}
                                            dataType="number"
                                        >
                                            <Format type="currency" currency='GBP' precision={2}></Format>
                                        </Column>
                                        <Column
                                            dataField="client_Billable_Amount_Local_Ccy"
                                            caption="TOTAL NET"
                                            allowGrouping={false}
                                            allowFiltering={false}
                                            allowSearch={false}
                                            allowSorting={false}
                                            dataType="number"
                                        >
                                            <Format type="currency" currency='GBP' precision={2}></Format>
                                        </Column>
                                        <Column
                                            dataField="idValue"
                                            caption="IDENTIFICATION NO."
                                        />
                                        <Column dataField="cover" caption="COVER" />
                                        <Column 
                                            dataField="billable_Start_Finish" 
                                            caption="BILLABLE START/FINISH" 
                                            allowGrouping={false}
                                            allowFiltering={false}
                                            allowSearch={false}
                                            allowSorting={false} 
                                        />
                                        <Column
                                            dataField="dateTo"
                                            caption="DATE TO"
                                            format="dd/MM/yyyy"
                                            dataType="date" /> 
                                        <Column
                                            dataField="payDate"
                                            caption="PAY DATE"
                                            format="dd/MM/yyyy"
                                            dataType="date" />
                                        <Column
                                            dataField="billDate"
                                            caption="BILL DATE"
                                            format="dd/MM/yyyy"
                                            dataType="date" />      
                                        <Column
                                            dataField="clientAmendDateTime"
                                            caption="CLIENT AMEND DATE"
                                            format="dd/MM/yyyy"
                                            dataType="date" 
                                        />
                                        <Column
                                            dataField="paymentStatus"
                                            caption="PAYMENT STATUS"/>
                                        <Column
                                            dataField="providerTaxApplicable"
                                            caption="PROVIDER VAT APPLICABLE?"
                                            allowGrouping={false}
                                            allowFiltering={false}
                                            allowSearch={false}
                                            allowSorting={false}
                                        />
                                        <Column
                                            dataField="clientTaxApplicable"
                                            caption="CLIENT VAT APPLICABLE?"
                                            allowGrouping={false}
                                            allowFiltering={false}
                                            allowSearch={false}
                                            allowSorting={false}
                                        />
                                        <Column
                                            dataField="providerPayAmountLocalCurrency"
                                            caption="PROVIDER PAY CURRENCY"
                                        />
                                        <Column
                                            dataField="providerOriginalPayAmount"
                                            caption="PROVIDER PAY ORIGINAL AMOUNT"
                                            dataType="number"
                                        >
                                            <Format type="currency" currency='GBP' precision={2}></Format>
                                        </Column>
                                        <Column
                                            dataField="grossProfitAmountLocalCurrency"
                                            caption="FEE CURRENCY"
                                        />
                                        <Column
                                            dataField="fee_Tax_Local_Ccy"
                                            caption="FEE VAT"
                                            allowGrouping={false}
                                            allowFiltering={false}
                                            allowSearch={false}
                                            allowSorting={false}
                                            dataType="number"
                                        >
                                            <Format type="currency" currency='GBP' precision={2}></Format>
                                        </Column>
                                        <Column
                                            dataField="fee_Total_Local_Ccy"
                                            caption="FEE TOTAL"
                                            allowGrouping={false}
                                            allowFiltering={false}
                                            allowSearch={false}
                                            allowSorting={false}
                                            dataType="number"
                                        >
                                            <Format type="currency" currency='GBP' precision={2}></Format>
                                        </Column>
                                        <Column dataField="grossProfitPercentageLocal" caption="FEE %" />
                                        <Column
                                            dataField="clientBillableAmountLocalCurrency"
                                            caption="CLIENT BILLABLE CURRENCY" />
                                        <Column
                                            dataField="client_Billable_Tax_Local_Ccy"
                                            caption="CLIENT BILLABLE VAT" 
                                            allowGrouping={false}
                                            allowFiltering={false}
                                            allowSearch={false}
                                            allowSorting={false} 
                                            dataType="number"
                                        >
                                            <Format type="currency" currency='GBP' precision={2}></Format>
                                        </Column>
                                        <Column
                                            dataField="client_Billable_Total_Local_Ccy"
                                            caption="CLIENT BILLABLE TOTAL" 
                                            allowGrouping={false}
                                            allowFiltering={false}
                                            allowSearch={false}
                                            allowSorting={false}
                                            dataType="number"
                                        >
                                            <Format type="currency" currency='GBP' precision={2}></Format>
                                        </Column>
                                        <Column
                                            dataField="clientOriginalBillableAmount"
                                            caption="CLIENT BILLABLE ORIGINAL AMOUNT"
                                            dataType="number"
                                        >
                                            <Format type="currency" currency='GBP' precision={2}></Format>
                                        </Column>

                                        <Column
                                            dataField="acceptDate"
                                            caption="ACCEPT DATE"
                                            format="dd/MM/yyyy"
                                            dataType="date" />
                                        <Column
                                            dataField="approvalDate"
                                            caption="APPROVAL DATE"
                                            format="dd/MM/yyyy"
                                            dataType="date" />
                                        {isEventUk && <Column dataField="sortCode" caption="SORT CODE" />}
                                        {isEventUk && <Column dataField="accountNumber" caption="ACCOUNT NUMBER" />}
                                        <Column
                                            dataField="nonStandard"
                                            caption="NON STANDARD" />
                                        <Column 
                                            dataField="batchId" 
                                            caption="BATCH" 
                                            allowGrouping={false}
                                            allowFiltering={false}
                                            allowSearch={false}
                                            allowSorting={false} 
                                        />
                                        <Column dataField="id" caption="ID" />
                                        {UserService.isUserInGroup(RoleGroupNames.EventUKSeniorManager) ?
                                            <Column
                                                headerCellRender={this.renderDelEditTitleV2Header}
                                                cellComponent={DeleteEditShiftActionCellV2}
                                                allowGrouping={false}
                                                allowFiltering={false}
                                                allowSearch={false}
                                                allowSorting={false}
                                            /> : null}
                                    </DataGrid>
                                </div>
                            </div>
                        </div>
                    </ScrollView>
                </Popup>
            </div>
        );
    }
}

export default withTranslation()(ShiftMatrixGridContents);