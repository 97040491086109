import React, { MouseEvent } from "react";
import { AxiosResponse,AxiosError } from "axios";
import DataGrid, {
    Pager,
    Paging,
    Export,
    FilterRow,
    Column,
    Scrolling,
    Editing,
    Texts,
    Selection,
    SearchPanel,
    FilterPanel,
    GroupPanel,
} from "devextreme-react/data-grid";
import gridUtils from "../grid/GridUtilities";
import sharedUtils,{B2cColumnOptions} from "../grid/sharedUtilities";
import { DataGridOnSelectionChanged, TextBoxOnValueChangedEvent, onExportingEvent } from "../../types/DevExtremeTypes";
import { LoadIndicator, Popup, ScrollView, TextArea,Button } from "devextreme-react";
import { confirm } from "devextreme/ui/dialog";
import ArtistProfileComponent from "../Artist-Profile/ArtistProfileComponent";
import FindAnArtistService, { artistGridRowItem } from "../../services/FindAnArtistService";
import ArtistService from "../../services/ArtistService";
import ArtistUtilities, { filterObject } from "./FindAnArtistUtils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser,faLink,faKey,faUserEdit } from "@fortawesome/free-solid-svg-icons";
import MatrixGridService from "../../services/MatrixService";
import { LoadPanel } from "devextreme-react";
import { Link } from "react-router-dom";
import { Workbook } from "exceljs";
import { exportDataGrid } from "devextreme/excel_exporter";
import { ServiceType } from "../../common/ServiceUtilities";
import GlobalService from "../../services/GlobalService";
import { GridTypes } from "../../services/SimpleGridService";
import notify from 'devextreme/ui/notify';
import AgreementExpiryActionCell from "../Artist-Profile/AgreementExpiryDateCellRenderer";

//props
interface ArtistGridProps {
    location: any;
    history: any;
    typeListObject?: filterObject;
    countOftypes?: number;
    requestStatus?: string;
    updateSearchButton: (flag: boolean) => void;
    getVenue: (client: string, venue: string) => void;
    isShowPopUp?: boolean;
    searchFieldVal: string;
    typeList: string;
    isClickArtistSearch?: boolean;
    trustedValue: boolean;
    rating: number;
    serviceType?: any;
    artistAdded: string;
    isRefresh?:boolean
    onRefresh?: (flag: boolean) => void;
}

// State
interface ArtistGridState {
    ArtistGridDataSource: artistGridRowItem[];
    showProgressIndicator: boolean;
    clientId: string;
    venueId: string;
    businessEntityId: string;
    serviceTypeId: string;
    providerPopupVisibleEntertainment?: boolean;
    profileNotificationPopupVisible?: boolean;
    bulletinNotificationPopupVisible?: boolean;
    title: string;
    description: string;
    profileBulletinNotification: any;
    providerId: [],
    loadPanelVisible: boolean;
    notiDescriptionPopupVisible: boolean;
    notiDescription: string;
    providerPopupVisible: boolean;
    type:string;
    selectedRows:any;
    showInviteLinkPopup:boolean;
    showErrorPopup:boolean;
    errorStatus:any;
    inviteLinkURL:string;
}

// Component - displays both the header and child grids
class ArtistGrid extends React.Component<ArtistGridProps> {
    state: ArtistGridState;
    service: FindAnArtistService;
    matrixService: MatrixGridService;
    gridUtils: gridUtils;
    utils: ArtistUtilities;
    sharedUtils: sharedUtils;
    isEditMode: string;
    artistService: ArtistService;
    dataGridRef:any;
    constructor(props: ArtistGridProps) {
        super(props);
        this.isEditMode = "";
        // Initialize state and services/utils
        this.state = {
            ArtistGridDataSource: [],
            showProgressIndicator: false,
            clientId: "",
            venueId: "",
            serviceTypeId: "",
            businessEntityId: "0",
            providerPopupVisibleEntertainment: false,
            profileNotificationPopupVisible: false,
            bulletinNotificationPopupVisible: false,
            title: "",
            description: "",
            profileBulletinNotification: {},
            providerId: [],
            loadPanelVisible: false,
            notiDescriptionPopupVisible: false,
            notiDescription: "",
            providerPopupVisible: false,
            type:'',
            selectedRows:[],
            showInviteLinkPopup:false,
            showErrorPopup:false,
            inviteLinkURL:'',
            errorStatus:{}
        };
        this.gridUtils = new gridUtils();
        this.utils = new ArtistUtilities();
        this.sharedUtils = new sharedUtils();
        // Functions
        this.service = new FindAnArtistService();
        this.matrixService = new MatrixGridService();
        this.artistService = new ArtistService();
        this.dataGridRef = null;
    } 

    getServiceId = (service:string) => {
        if(service == "ContractCleaning"){
            return ServiceType.ContractCleaning;
        }
        else if(service == "Security"){
            return ServiceType.Security;
        }
        else if(service == "Gardening"){
            return ServiceType.Gardening;
        }
        else if(service == "Maintenance"){
            return ServiceType.Maintenance;
        }
        else if(service == "Miscelleanous"){
            return ServiceType.Miscelleanous;
        }
        else if(service == "WindowCleaning"){
            return ServiceType.WindowCleaning;
        }
        else {
            return "";
        }
    }

    componentDidMount() {
        let envelopeId = localStorage.getItem("EnvelopeId");
        let businessEntityId = localStorage.getItem("businessEntityId");  
        let dateFrom = localStorage.getItem("agreementDateFrom");
        let dateTo = localStorage.getItem("agreementDateTo");
        let gridType = GlobalService.getGridType();    
        if (this.props.location?.state?.navigateFrom == "viewClients") {
            this.service.getListOfArtistsGridClient("viewClients").then(this.handleSuccess).catch(this.handleFailure);
        }
        if (gridType && gridType == GridTypes.providerShiftMatrix && businessEntityId !== "" && businessEntityId !== null)  {
            this.setState({
                businessEntityId: businessEntityId,
                providerPopupVisible: true,
            });
            this.isEditMode == "editMode"
        }
        if(envelopeId && businessEntityId && dateFrom && dateTo){
            this.setState({loadPanelVisible : true});
            this.artistService.getSignedInformation(envelopeId, businessEntityId,dateFrom,dateTo).then(this.handleSuccessSignedInfo).catch(this.handleErrorSignedInfo);
        }
        localStorage.setItem("businessEntityId", "");
        GlobalService.setGridType("");
    }
    
    componentDidUpdate = (prevprops: ArtistGridProps, prevState: ArtistGridState) => {
        let { searchFieldVal, typeList, trustedValue, rating } = this.props;    
        if(this.props.serviceType != prevprops.serviceType){
            this.setState({
                ArtistGridDataSource: [],
            },()=>{
                if (this.props.location?.state?.navigateFrom == "viewClients") {
                    this.service.getListOfArtistsGridClient("viewClients").then(this.handleSuccess).catch(this.handleFailure);
                }
            });
        }   
        if(this.props.isClickArtistSearch && this.props.isClickArtistSearch != prevprops.isClickArtistSearch){
            this.setState({loadPanelVisible:true});
            if(this.props.serviceType != "Entertainment"){             
                this.service.getListOfArtistsGridOther(searchFieldVal, this.getServiceId(this.props.serviceType)).then(this.handleSuccess).catch(this.handleFailure);
            }
            else {
                this.service.getListOfArtistsGrid(searchFieldVal, typeList, trustedValue, rating, "30").then(this.handleSuccess).catch(this.handleFailure);
            }            
            this.props.updateSearchButton(false);
        }
        if (this.props.artistAdded !== prevprops.artistAdded || (this.props.isRefresh != prevprops.isRefresh && this.props.isRefresh == true)) {
            //if(this.props.isRefresh != prevprops.isRefresh && this.props.isRefresh == true){
                this.setState({loadPanelVisible:true});
            //}
            if (this.props.location?.state?.navigateFrom == "viewClients") {
                this.service.getListOfArtistsGridClient("viewClients").then(this.handleSuccess).catch(this.handleFailure);
            }
            else{
                this.service.getListOfArtistsGridOther(searchFieldVal, this.getServiceId(this.props.serviceType)).then(this.handleSuccess).catch(this.handleFailure);
            }            
            this.props.updateSearchButton(false);
            this.props.onRefresh ? this.props.onRefresh(false) :null;
        }
    };

    handleSuccessSignedInfo = (response: AxiosResponse<any>) => {
        let businessEntityId = response?.data?.data?.businessEntityId || '';
        this.setState({
            businessEntityId: businessEntityId,
            providerPopupVisible: true, //// to show edit popup
            loadPanelVisible:false
        });
        //this.showInfoProvider(businessEntityId);
        //this.handlePopUpClose(true, "editMode");
        this.isEditMode = "editMode";
        localStorage.setItem("EnvelopeId", "");
        localStorage.setItem("businessEntityId", "");
        localStorage.setItem("agreementDateFrom", "");
        localStorage.setItem("agreementDateTo", "");
    }

    handleErrorSignedInfo = (response: AxiosResponse<any>) => {
        this.setState({
            loadPanelVisible: false
        });
        localStorage.setItem("EnvelopeId", "");
        localStorage.setItem("businessEntityId", "");
        localStorage.setItem("agreementDateFrom", "");
        localStorage.setItem("agreementDateTo", "");
        // Log to AppInsights as a TRACE
        var respMessage: string = "Sign document is failed: " + JSON.stringify(response);

        if (!this.artistService.traceAsErrorToAppInsights(respMessage)) {
            // AppInsights is not available
            console.error(respMessage);
        }
    };

    handleSuccess = (response: AxiosResponse<any>) => {
        for(let item of response.data.data){
            item.typeList = JSON.stringify(item.typeList);
        }
        this.setState(
            {
                loadPanelVisible: false,
                showProgressIndicator: false,
                cards: response.data.data,
                ArtistGridDataSource: response.data.data,
                cardsLoaded: true,
            });
    };

    handleProfileSuccess = () => {
        this.setState(
            {
                loadPanelVisible: false,
                showProgressIndicator: false,
                title: "",
                description: "",
                profileNotificationPopupVisible: false,
                bulletinNotificationPopupVisible: false,
            });
    };

    handleFailure = () => {
        this.setState({
            loadPanelVisible: false,
            showProgressIndicator: false,
        });
    };

    showInfoProvider = (businessEntityId: string,rowData:any) => {
        let type = rowData?.type;
        this.setState({
            businessEntityId: businessEntityId,
            providerPopupVisibleEntertainment: true,
            type:type?.toLowerCase()
        });
    };

    showProfileNotificationPopup = () => {
        this.setState({
            profileNotificationPopupVisible: true,
        });
    };

    showBulletinNotificationPopup = () => {
        this.setState({
            bulletinNotificationPopupVisible: true,
        });
    };

    handleNotificationPopUpClose = () => {
        this.setState({
            profileNotificationPopupVisible: false,
            bulletinNotificationPopupVisible: false,
            notiDescriptionPopupVisible: false,
        });
    }

    handleChangeNotiTitle = (dxValueChange: TextBoxOnValueChangedEvent) => {
        this.setState({
            title: dxValueChange.value
        });
    };

    handleChangeNotidescription = (dxValueChange: TextBoxOnValueChangedEvent) => {
        this.setState({
            description: dxValueChange.value
        });
    };

    sendProfileNotification = () => {
        this.setState({
            showProgressIndicator: true,
            loadPanelVisible: true,
        });
        const data= {
            description: this.state.description,
            title: this.state.title,
            type: "Profile",
            bulletinId: "",
            providerIds: this.state.providerId,
        }
        this.service.sendArtistNotifications(data).then(this.handleProfileSuccess).catch(this.handleFailure);
    }

    sendBulletinNotification = () => {
        this.setState({
            showProgressIndicator: true,
            loadPanelVisible: true,
        });
        let today = new Date();
        let dateFrom = new Date();
        let dateTo = today.setDate(today.getDate() + 1);
        let bulletinDateTo = new Date(dateTo);
        const bulletinData= {
            id: "0",
            bulletinCategoryId: "10",
            bulletinPriorityId: "20",
            bulletinTitle: this.state.title,
            bulletinName: "Artist View",
            bulletinDescription: this.state.description,
            bulletinDate: this.sharedUtils.convertDateToString(dateFrom),
            validFrom: this.sharedUtils.convertDateToString(dateFrom),
            validTo: this.sharedUtils.convertDateToString(bulletinDateTo),
            isActive: true,
            isPopUpRequired: false,
            serviceTypeID: "30",
            userRoleID: null,
            businessEntityID: null,
            navigateFrom: "profile",
        }
        this.matrixService
            .postBulletinFormData(bulletinData)
            .then(this.handleSuccessDataSubmission)
            .catch((err: any) => {
                this.setState({
                    errorMessage:
                        err.response && err.response.data && err.response.data.error
                            ? JSON.parse(JSON.stringify(err.response.data.error))
                            : null,
                    showProgressIndicator: false,
                    loadPanelVisible: false,
                });
            });
    }

    handleSuccessDataSubmission = (response: AxiosResponse<any>) => {
        this.setState({
            showProgressIndicator: false,
        });
        const notiData= {
            description: this.state.description,
            title: this.state.title,
            type: "Bulletin",
            bulletinId: response.data.toString(),
            providerIds: this.state.providerId,
        }
        this.service.sendArtistNotifications(notiData).then(this.handleProfileSuccess).catch(this.handleFailure);
    };


    handlePopUpClose = (flag: boolean, editmode: string) => {
        this.isEditMode = editmode;
        this.setState({
            providerPopupVisibleEntertainment: flag,
            profileNotificationPopupVisible: false,
            bulletinNotificationPopupVisible: false
        });
        if(editmode == ""){
            let { searchFieldVal, typeList, trustedValue, rating } = this.props;
            this.setState({loadPanelVisible:true,});
            if(this.props.serviceType != "Entertainment"){
                if (this.props.location?.state?.navigateFrom == "viewClients") {
                    this.service.getListOfArtistsGridClient("viewClients").then(this.handleSuccess).catch(this.handleFailure);
                }
                else{
                    this.service.getListOfArtistsGridOther(searchFieldVal, this.getServiceId(this.props.serviceType)).then(this.handleSuccess).catch(this.handleFailure);
                }
            }
            else {
                this.service.getListOfArtistsGrid(searchFieldVal, typeList, trustedValue, rating, "30").then(this.handleSuccess).catch(this.handleFailure);
            }  
            this.props.updateSearchButton(false);
        }
    }

    getServiceType = () =>{
        let serviceType = this.props.serviceType;
        if(serviceType && serviceType == 'viewProviders'){
            if(this.state.type && this.state.type == 'artist'){
                serviceType = 'Entertainment';
            }
        }
        return serviceType;
    }

    getTitleForPopup = () =>{
        let serviceType = this.props.serviceType;
        let title = '';
        if(serviceType != "Entertainment"){
            if(serviceType == 'ViewClients'){
                title = 'Client Details';
            } else if(serviceType == 'viewProviders'){
              title = "Provider Details";
              if(this.state.type && this.state.type == 'artist'){
                title = "Artist Details";
              }
            }

        } else {
            title = "Artist Details"
        }
        return title;
    }

    hideInfoProviderArtistProfile = (e: any) => {
        e.cancel = true;
        if (this.isEditMode == "editMode") {
            confirm("<div class='text-center'>Close screen without saving?</div>", "Close").then((hasConfirmed) => {
                if (hasConfirmed) {
                    this.setState({
                        providerPopupVisibleEntertainment: false,
                        providerPopupVisible: false,
                    });
                    this.isEditMode = "";
                }
            });
        }
        else {
            this.setState({
                providerPopupVisibleEntertainment: false,
                providerPopupVisible: false,
            });
        }
    };

    artistProfileDisplay = (cellInfo: any) => {
        return (
            <div className="d-flex justify-content-between align-items-center">
                <button
                    className={"btn icon-btn"}
                    onClick={(e: MouseEvent) => {
                        e.preventDefault();
                        this.showInfoProvider(cellInfo.data.providerId,cellInfo?.data);
                    }}
                >
                    <FontAwesomeIcon icon={faUser} />
                </button>
            </div>
        );
    }

    typeDisplay = (cellInfo: any) => {
        let typeListArr= cellInfo.data.typeList !== "" ? JSON.parse(cellInfo.data.typeList) : [];
        return (
            <span>
                <div className="mb-0">
                    {typeListArr
                        ? typeListArr.map((item: any, uniqueKey: number) => {
                              return (
                                <>
                                    { item.value.indexOf("-") !== -1 ?
                                        <div key={uniqueKey}>
                                            {item.value}
                                        </div> 
                                    : null }
                                </>
                              );
                          })
                        : null}
                    {typeListArr
                        ? typeListArr.map((item: any, uniqueKey: number) => {
                              return (
                                <>
                                    { item.value.indexOf("-") == -1 ?
                                        <div key={uniqueKey}>
                                            {item.value}
                                        </div> 
                                    : null}
                                    <div>
                                        {item.otherDescription}
                                    </div>
                                </>
                              );
                          })
                        : null}
                </div>
            </span>
        );
    }

    typeDisplayCalculate = (cellInfo: any) => {
        let typeListArr= cellInfo.typeList !== "" ? JSON.parse(cellInfo.typeList) : [];
        let stringVal = typeListArr ? typeListArr.map((item:any)=>{
            return `${item.value ? item.value :''} ${item.otherDescription ? item.otherDescription :''}`
        }).join('\n'):'';
        return (
            stringVal
        );
    }

    clientServiceDisplay = (cellInfo: any) => {
            return (
                <span>
                    <div className="mb-0">
                        {cellInfo.data.services
                            ? cellInfo.data.services.map((item: any, uniqueKey: number) => {
                                return (
                                    <>
                                        <div key={uniqueKey}>
                                            {item}
                                        </div>
                                    </>
                                );
                            })
                            : null}
                    </div>
                </span>
            );
    }
    clientServiceCalculate = (cellInfo: any) => {
        let servicesArr = cellInfo.services ? cellInfo.services : [];
        let stringVal = servicesArr && servicesArr.length && servicesArr.length > 0 ? servicesArr.map((item:any)=>{
            return `${item}`
        }).join(','):'';
        return stringVal;
    }

    onSelectionChanged = (value: DataGridOnSelectionChanged) => {
        let providerIds:any = [];
        if (value.selectedRowsData && value.selectedRowsData.length > 0) {
            value.selectedRowsData.forEach((items) => {
                providerIds.push(items.providerId);
            });
            this.setState({providerId: providerIds})
        }
        else{
            this.setState({providerId: []});
        }
        this.setState({selectedRows:value.selectedRowsData});
    };

    notiDescriptionCellDisplayContent = (cellInfo: any) => {
        return (
            <div>
                <Link
                    onClick={(e: MouseEvent) => {
                        e.preventDefault();
                        this.showInfoNotiDescription(cellInfo.data.notificationBody);
                    }}
                    className="approval-query-column"
                    to="#"
                >
                    {cellInfo.data.notificationBody
                        ? cellInfo.data.notificationBody.length > 16
                            ? `${cellInfo.data.notificationBody.substring(0, 16)}...`
                            : cellInfo.data.notificationBody
                        : ""}
                </Link>
            </div>
        );
    };

    notiDateTimeDisplay = (cellInfo: any) => {

        let finalFormattedDate: string = "";
        let dateCreatedTime = cellInfo.data.notificationDateTime;
        let dateCreatedTimeLocal = new Date(dateCreatedTime);
        let formattedTime = this.sharedUtils.convertDateTimeStringToHHMM(dateCreatedTimeLocal.toString());
        let formattedDate = this.sharedUtils.convertDateToString(dateCreatedTimeLocal);
        finalFormattedDate = `${formattedDate} ${formattedTime}`;
        return (
            <div>
                {cellInfo.data?.notificationDateTime !== null && cellInfo.data?.notificationDateTime !== ""
                    ? finalFormattedDate
                    : ""}
            </div>
        );
    };

    createdDateTimeDisplay = (cellInfo: any) => {

        let finalFormattedDate: string = "";
        let dateCreatedTime = cellInfo.data.createdDateTime;
        if(dateCreatedTime){
        let dateCreatedTimeLocal = new Date(dateCreatedTime);
        let formattedTime = this.sharedUtils.convertDateTimeStringToHHMM(dateCreatedTimeLocal.toString());
        let formattedDate = this.sharedUtils.convertDateToString(dateCreatedTimeLocal);
        finalFormattedDate = `${formattedDate} ${formattedTime}`;
        }
        return (
            <div>{finalFormattedDate}</div>
        );
    };

    notiLastLoginDisplay = (cellInfo: any) => {
        let finalFormattedDate: string = "";
        let dateCreatedTime = cellInfo.data.lastLoggedIn;
        let dateCreatedTimeLocal = new Date(dateCreatedTime);
        let formattedTime = this.sharedUtils.convertDateTimeStringToHHMM(dateCreatedTimeLocal.toString());
        let formattedDate = this.sharedUtils.convertDateToString(dateCreatedTimeLocal);
        finalFormattedDate = `${formattedDate} ${formattedTime}`;
        return (
            <div>
                {cellInfo.data?.lastLoggedIn !== null && cellInfo.data?.lastLoggedIn !== "" 
                    ? finalFormattedDate
                    : ""}
            </div>
        );
    };

    allowNotiCellDisplayContent = (cellInfo: any) => {
        return (
            <div>
                {cellInfo.data.allowNotifications && cellInfo.data.allowNotifications == "True"
                    ? cellInfo.data.allowNotifications
                    : "False"
                }
            </div>
        );
    }
    allowNotificationsCellCalculate = (cellInfo: any) => {
        return (
                cellInfo.allowNotifications && cellInfo.allowNotifications == "True"
                    ? cellInfo.allowNotifications
                    : "False"
        );
    }

    showInfoNotiDescription = (description: string) => {
        this.setState({
            notiDescriptionPopupVisible: true,
            notiDescription: description,
        });
    };

    parseTypeSubTypeObjectIntoReadableStringMethod = (typeList: string[]) => {
        let str = "";
        typeList.forEach((item: any) => {
            str += `${item.value}\n ${item.otherDescription} \n`;            
        });
        return str ;
    };

    onExporting = (e: onExportingEvent) => {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet("Main sheet");
        exportDataGrid({
            component: e.component,
            worksheet: worksheet,
            autoFilterEnabled: true,
            topLeftCell: { row: 2, column: 2 },
            customizeCell: ({ gridCell, excelCell }) => {
                if (gridCell && gridCell.rowType === "data") {
                    if (gridCell.column && gridCell.column.dataField === "typeList1") {
                        excelCell.value = this.parseTypeSubTypeObjectIntoReadableStringMethod(gridCell.value ? JSON.parse(gridCell.value) : []);
                    }
                }
            },
        }).then(() => {
            workbook.xlsx.writeBuffer().then((buffer) => {
                saveAs(new Blob([buffer], { type: "application/octet-stream" }), "DataGrid.xlsx");
            });
        });
        e.cancel = true;
    };

    
    showB2cColumns = () => {
        let show = false;
        if(this.props.serviceType && this.props.serviceType == "Entertainment"){
            show = true;
        }
        return show;
    }

    showAgreementColumns = () => {
        let show = false;
        if(this.props.serviceType && (this.props.serviceType == "Entertainment" || this.props.serviceType == "viewProviders")){
            show = true;
        }
        return show;
    }

    b2cActions =(cellInfo:any,type:string)=>{
      let header = '';
      if(type){
        if(type == B2cColumnOptions.INVITELINK){
            header = `Get Invite Link?`
        } else if(type == B2cColumnOptions.CREATEACCOUNT){
            header = 'Create Account?'
        } 
        // else if(type == B2cColumnOptions.RESETPASSWORD){
        //     header = 'Reset Password?'
        // } 
        if(header){
            // if(header && cellInfo?.data?.id){
            //     header = `${header} contact id - ${cellInfo?.data?.id}`;
            // }
            confirm(`<div class='text-center'>${header}</div>`, `Submit`).then((hasConfirmed) => {
                if (hasConfirmed) {
                    this.onConfirmation(type,cellInfo.data);
                }
            });
        }
      }
    }

    renderB2cColumns = (cellInfo:any,type:string)=>{
        if(type && type == B2cColumnOptions.INVITELINK){
            return (
                <div className="d-flex justify-content-between align-items-center">
                    <button
                        className={"btn icon-btn"}
                        onClick={(e: MouseEvent) => {
                            e.preventDefault();
                            this.b2cActions(cellInfo,type);
                        }}
                    >
                        <FontAwesomeIcon icon={faLink} />
                    </button>
                </div>
            );
        } else if(type && type == B2cColumnOptions.CREATEACCOUNT){
            return (
                <div className="d-flex justify-content-between align-items-center">
                    <button
                        className={"btn icon-btn"}
                        onClick={(e: MouseEvent) => {
                            e.preventDefault();
                            this.b2cActions(cellInfo,type);
                        }}
                    >
                        {/* <FontAwesomeIcon icon={faUser} /> */}
                        <FontAwesomeIcon icon={faUserEdit} />
                        {/* <FontAwesomeIcon icon={<img width="16" height="16" src="https://img.icons8.com/tiny-glyph/16/edit-user-male.png" alt="edit-user-male"/>} /> */}
                        {/* <img width="16" height="16" src="https://img.icons8.com/tiny-glyph/16/edit-user-male.png" alt="edit-user-male"/> */}
                    </button>
                </div>
            );
        } else if(type && type == B2cColumnOptions.RESETPASSWORD){
            return (
                <div className="d-flex justify-content-between align-items-center">
                    <button
                        className={"btn icon-btn"}
                        onClick={(e: MouseEvent) => {
                            e.preventDefault();
                            this.b2cActions(cellInfo,type);
                        }}
                    >
                        <FontAwesomeIcon icon={faKey} />
                        {/* <FontAwesomeIcon icon ={faKeySkeleton}/> */}
                    </button>
                </div>
            );
        } 
        //else{
            return null;
        //}
        
    }

    isBulkInviteDisabled = () => {
        let isDisabled = true;
        if (this.state.selectedRows && this.state.selectedRows.length && this.state.selectedRows.length > 0) {
            isDisabled = false;
        }
        return isDisabled;
    }

    handleBulkInviteClick = ()=>{
            if(this.state.selectedRows && this.state.selectedRows.length > 0){
                confirm("<div class='text-center'>Send Bulk Invite?</div>", "Submit").then((hasConfirmed) => {
                    if (hasConfirmed) {
                        this.onConfirmation(B2cColumnOptions.BULKINVITE,this.state.selectedRows);
                    }
                });
            }
    }
    
    onConfirmation = (type:string,reqData:any) => {
        this.setState({loadPanelVisible:true,inviteLinkURL:'',errorStatus:{}});
            switch (type) {
                case B2cColumnOptions.BULKINVITE:
                    let request = reqData.map((item:any)=>{
                        return {
                            id:item.contactId,
                            displayName:item.professionalName,
                            email:item.email,
                            userId :item.userId
                        }
                    })
                    this.matrixService.sendBulkInvites(request)
                       .then((response)=>this.handleB2cSuccess(response,type))
                       .catch((error)=>{this.handleB2cError(error,type)})
                    break;
                case B2cColumnOptions.INVITELINK:
                case B2cColumnOptions.CREATEACCOUNT:
                    let reqObj = {
                        id:reqData.contactId,
                        displayName:reqData.professionalName,
                        email:reqData.email,
                        userId :reqData.userId
                    }
                    this.matrixService.getInviteLink(reqObj)
                    .then((response)=>this.handleB2cSuccess(response,type))
                    .catch((error)=>{this.handleB2cError(error,type)})
                    break;
                case B2cColumnOptions.RESETPASSWORD:
                    let req = {
                        id:reqData.contactId,
                        displayName:reqData.professionalName,
                        email:reqData.email
                    }
                    this.matrixService.resetPassword(req)
                    .then((response:any)=>this.handleB2cSuccess(response,type))
                    .catch((error:any)=>{this.handleB2cError(error,type)})
                    break;
                case 'default':
                    break;
            }
    };

    createErrorObject = (data:any) => {
        let errorObj = {
            success: [],
            error: [],
            validations: []
        }
        let showErros = false;
        if(data && data.length && data.length > 0){
            let success = data.filter((item:any)=>{return item.success});
            let error = data.filter((item:any)=>{return !item.success && item.validationErrors?.length <= 0});
            let validation = data.filter((item:any)=>{return !item.success && item.validationErrors?.length > 0});
            errorObj = {...errorObj,...{success :success,error:error,validations:validation}}
            showErros = true;
        }
        this.setState({errorStatus:errorObj,showErrorPopup:showErros})
    }

    inviteLinkValidations = (errorResponse:any) =>{
        let validationError = '';
        if(errorResponse && errorResponse.validationErrors?.length && errorResponse.validationErrors?.length > 0){
            validationError = errorResponse[0].errorMessage;
        }
        return validationError;
    }

    handleB2cSuccess = (response: AxiosResponse<any>, type: string) => {
        this.setState({ loadPanelVisible: false }, () => {
            if (type) {
                if (type == B2cColumnOptions.BULKINVITE) {
                    this.dataGridRef?.instance?.clearSelection();
                    if (response?.data?.data) {
                        let allSuccess = response?.data?.data.every((item: any) => { return item.success });
                        if (allSuccess) {
                            notify({ message: "Thank you - invites has been sent to all contacts.", width: 300, shading: true, position: "center" }, 'success', 600);
                        } else {
                            this.createErrorObject(response?.data?.data);
                        }
                    }

                } else if (type == B2cColumnOptions.INVITELINK) {
                    let inviteLink = response?.data?.data?.inviteLink || '';
                    let hasInviteLink = inviteLink ? true : false;
                    let error = '';
                    if(!hasInviteLink)
                    error = this.inviteLinkValidations(response?.data?.data);
                    if (hasInviteLink) {
                        this.setState({
                            inviteLinkURL: inviteLink || '',
                            showInviteLinkPopup: true
                        });
                    } else if(error){
                    notify({ message: error, width: 300, shading: true }, 'error', 600);
                    }
                    else {
                        notify({ message: "Sorry! invite link could not be generated.", width: 300, shading: true }, 'error', 600);
                    }

                } else if (type == B2cColumnOptions.CREATEACCOUNT) {
                    let inviteLink = response?.data?.data?.inviteLink || '';
                    let hasInviteLink = inviteLink ? true : false;
                    let error = '';
                    if(!hasInviteLink)
                    error = this.inviteLinkValidations(response?.data?.data);
                    if (hasInviteLink) {
                        window.open(inviteLink, '_blank', 'noreferrer');
                    } else if(error) {
                        notify({ message: error, width: 300, shading: true }, 'error', 600);
                    } else { 
                        notify({ message: "Sorry! account could not be generated.", width: 300, shading: true }, 'error', 600);
                    }

                } else if (type == B2cColumnOptions.RESETPASSWORD) {
                    notify({ message: "Thank you - password reset link has been sent.", width: 300, shading: true, position: "center" }, 'success', 600);
                }
            }
        })
    }

    handleB2cError = (error: AxiosError<any>, type: string) => {
        this.setState({ loadPanelVisible: false }, () => {
            this.dataGridRef?.instance?.clearSelection();
            if (type) {
                if (type == B2cColumnOptions.BULKINVITE) {
                    var respMessage: string = "Bulk invite failed with response: " + JSON.stringify(error);
                    notify({ message: "Sorry! Bulk invite could not be sent.", width: 300, shading: true }, 'error', 600);
                    if (!this.matrixService.traceAsErrorToAppInsights(respMessage)) {
                        console.error(respMessage);
                    }

                } else if (type == B2cColumnOptions.INVITELINK) {
                    var respMessage: string = "Get invite link failed with response: " + JSON.stringify(error);
                    if (error.response?.data?.error && error.response?.data?.error.length && error.response?.data?.error.length > 0) {
                        let errorMessage = error.response.data.error[0].errorMessage;
                        notify({ message: errorMessage, width: 300, shading: true }, 'error', 600);
                    }
                    else {
                        notify({ message: "Sorry! invite link could not be generated.", width: 300, shading: true }, 'error', 600);
                    }

                    if (!this.matrixService.traceAsErrorToAppInsights(respMessage)) {
                        console.error(respMessage);
                    }

                } else if (type == B2cColumnOptions.CREATEACCOUNT) {
                    var respMessage: string = "create account failed with response: " + JSON.stringify(error);
                    //notify({ message: "Sorry! account could not be generated.", width: 300, shading: true }, 'error', 600);
                    if (error.response?.data?.error && error.response?.data?.error.length && error.response?.data?.error.length > 0) {
                        let errorMessage = error.response.data.error[0].errorMessage;
                        notify({ message: errorMessage, width: 300, shading: true }, 'error', 600);
                    }
                    else {
                        notify({ message: "Sorry! account could not be generated.", width: 300, shading: true }, 'error', 600);
                    }

                    if (!this.matrixService.traceAsErrorToAppInsights(respMessage)) {
                        console.error(respMessage);
                    }
                } else if (type == B2cColumnOptions.RESETPASSWORD) {
                    var respMessage: string = "reset password failed with response: " + JSON.stringify(error);
                    notify({ message: "Sorry! reset password link could not be generated.", width: 300, shading: true }, 'error', 600);
                    if (!this.matrixService.traceAsErrorToAppInsights(respMessage)) {
                        console.error(respMessage);
                    }
                }
            }
        })
    }

    hideInvitePopup = () => {
        this.setState({showInviteLinkPopup: false,inviteLinkURL:''});
    };
    hideErrorPopup = () => {
        this.setState({showErrorPopup: false,errorStatus:{}});
    };

    onCopyClick = () =>{
        //this.setState({showInviteLinkPopup: false},()=>{
            navigator?.clipboard?.writeText(this.state.inviteLinkURL);
            //alert("", "success");
            notify({ message: "Link copied to the clipboard.", width: 300, shading: true, position: "center" }, 'success', 600);
        //});
    }

    renderInviteLinkPopup = () => {
        return(
            <Popup
                visible={this.state.showInviteLinkPopup}
                onHiding={this.hideInvitePopup}
                dragEnabled={false}
                showTitle={true}
                showCloseButton={true}
                title={"INVITE LINK GENERATED"}
                width={"30%"}
                height={400}
            >
                <ScrollView width='100%' height='80%'>
                {/* <ScrollView> */}
                    <div>
                    <div className="text-center card-form__heading" style={{paddingRight:'10px',wordBreak:'break-all'}}>{this.state.inviteLinkURL}</div>
                    </div>
                </ScrollView>
                <div className="row justify-content-md-center">
                        <div className="col-3">
                            <button
                                className="btn btn-primary btn--large mt-3"
                                type="submit"
                                onClick={(e: MouseEvent) => {
                                    e.preventDefault();
                                    this.onCopyClick()
                                }}
                            >
                                COPY
                            </button>
                        </div>
    
                        <div className="col-3">
                            <button
                                className="btn btn--ghost btn--ghost--teal btn--large mt-3"
                                onClick={(e: MouseEvent) => {
                                    e.preventDefault();
                                    this.hideInvitePopup();
                                }}
                            >
                                CLOSE
                            </button>
                        </div>
                </div>
            </Popup>
            )
    }

    renderErrorPopup = () => {
        return (
            <Popup
                visible={this.state.showErrorPopup}
                onHiding={this.hideErrorPopup}
                dragEnabled={false}
                showTitle={true}
                showCloseButton={true}
                title={"BULK INVITE STATUS"}
                width={"30%"}
                height={400}
            >
                <ScrollView width='100%' height='80%'>
                    <div>
                        {/* <div className="text-center card-form__heading" style={{ paddingRight: '10px', wordBreak: 'break-all' }}>{this.state.inviteLinkURL}</div> */}
                        {this.state.showErrorPopup && this.state.errorStatus?.error && this.state.errorStatus?.error?.length > 0 ? (
                            <>
                                <h5 className="unscheduled-shift">Failed :</h5>
                                <span className="unscheduled-shift">
                                    <ul>
                                        {this.state.errorStatus?.error?.map((item: any, uniqueKey: number) => {
                                            return (
                                                <li key={uniqueKey}>
                                                    {/* {item.columnName}: {item.errorMessage} */}
                                                    {`Invite failed for contact`} : {item.id}
                                                </li>
                                            );
                                        })}
                                    </ul>
                                </span>
                            </>
                        ) : null}
                        {this.state.showErrorPopup && this.state.errorStatus?.validations && this.state.errorStatus?.validations?.length > 0 ? (
                            <>
                                <h5 className="unscheduled-shift">Issues :</h5>
                                <span className="unscheduled-shift">
                                    <ul>
                                        {/* {this.state.errorStatus?.validations?.validationErrors?.map((item: any, uniqueKey: number) => {
                                            return (
                                                <li key={uniqueKey}>
                                                    {item.columnName}: {item.errorMessage}
                                                </li>
                                            );
                                        })} */}
                                        {this.state.errorStatus?.validations?.map((item: any, uniqueKey: number) => {
                                            {
                                               return (
                                                <>
                                                {item.validationErrors.map((error: any) => {
                                                    return (
                                                        <li key={uniqueKey}>
                                                            {/* {error.columnName} :  */}
                                                            {error.errorMessage} ( contact - {item.id} )
                                                        </li>)
                                                })}
                                                </>
                                               )
                                                
                                            }
                                        })}
                                    </ul>
                                </span>
                            </>
                        ) : null}
                        {this.state.showErrorPopup && this.state.errorStatus?.success && this.state.errorStatus?.success?.length > 0 ? (
                            <>
                                <h5 className="unscheduled-shift-success">Successful :</h5>
                                <span className="unscheduled-shift-success">
                                    <ul>
                                        {this.state.errorStatus?.success?.map((item: any, uniqueKey: number) => {
                                            return (
                                                <li key={uniqueKey}>
                                                    {/* {item.columnName}: {item.errorMessage} */}
                                                    {`invite sent to contact`} : {item.id}
                                                </li>
                                            );
                                        })}
                                    </ul>
                                </span>
                            </>
                        ) : null}
                    </div>
                </ScrollView>
                <div className="row justify-content-md-center">
                    <div className="col-3">
                        <button
                            className="btn btn-primary btn--large mt-3"
                            type="submit"
                            onClick={(e: MouseEvent) => {
                                e.preventDefault();
                                this.hideErrorPopup()
                            }}>
                            OK
                        </button>
                    </div>
                </div>
            </Popup>
        )
    }


    render() {
        return (
            <div>
                <LoadPanel shadingColor="rgba(0,0,0,0.4)" visible={this.state.loadPanelVisible} />
                {this.state.showProgressIndicator ? (
                    <div className="starter-template">
                        <LoadIndicator
                            id="simple-grid-indicator"
                            height={100}
                            width={100}
                            visible={this.state.showProgressIndicator}
                        />
                    </div>
                ) : ( this.props.serviceType != "Entertainment" ? 
                    <DataGrid
                        dataSource={this.state.ArtistGridDataSource}
                        showBorders={false}
                        showColumnLines={false}
                        hoverStateEnabled={true}
                        columnAutoWidth={true}
                        allowColumnResizing={true}
                        allowColumnReordering={true}
                        columnResizingMode={"widget"}
                        noDataText={"No data found for the filter applied - please correct or expand the filters used"}
                        columnMinWidth={100}
                        keyExpr="providerId"
                        onExporting={this.onExporting}
                    >
                        <SearchPanel visible={true} placeholder={"Search"} />
                        <GroupPanel visible={true} />
                        <FilterPanel visible={true} />
                        <Editing>
                            <Texts confirmDeleteMessage=""></Texts>
                        </Editing>
                        <Scrolling useNative={true} showScrollbar={"always"} />
                        <Export enabled={true} />
                        <Paging defaultPageSize={10} />
                        <Pager showPageSizeSelector={true} allowedPageSizes={[5, 10, 20]} showInfo={true} />
                        
                        <Column
                            caption="PROFILE"
                            allowExporting={false}
                            allowFiltering={false}
                            allowSorting={false}
                            allowGrouping={false}
                            cellRender={this.artistProfileDisplay}
                        />
                        <Column 
                            dataField="verified" 
                            caption="Verified" 
                        />
                        <Column 
                            dataField="providerId" 
                            caption={this.props.location?.state?.navigateFrom == "viewClients" ? "Client Id" : "Provider Id"}
                            calculateSortValue={(rowData: any) => {
                                return this.gridUtils.convertStringToInt(rowData.providerId);
                            }}
                        />
                        {this.props.serviceType && this.props.serviceType == 'viewProviders' ? <Column 
                            dataField="type" 
                            caption={"TYPE"}
                        />: null}
                        <Column 
                            dataField="legalName" 
                            caption="Legal Name" 
                        />
                        <Column 
                            dataField="shortName" 
                            caption="Short Name" 
                        />
                        { this.props.location?.state?.navigateFrom == "viewClients" || this.props.location?.state?.navigateFrom == "viewProviders" ? 
                            <Column 
                                dataField="services" 
                                caption="Services"
                                cellRender={this.clientServiceDisplay} 
                                //calculateCellValue={this.clientServiceCalculate}
                            /> : null
                        }
                        <Column 
                            dataField="email" 
                            caption="Email" 
                        />
                        <Column 
                            dataField="addressStreet1" 
                            caption="Address" 
                        />
                        <Column 
                            dataField="townCity" 
                            caption="Town/City" 
                        />
                        <Column 
                            dataField="postCode" 
                            caption="Post Code" 
                        />
                        <Column 
                            dataField="phone" 
                            caption="Phone" 
                        />
                        <Column 
                            dataField="businessType" 
                            caption="Business Type" 
                        />
                        <Column 
                            dataField="companyNumber" 
                            caption="Company No." 
                        />
                        <Column 
                            dataField="vatVerified" 
                            caption="VAT Verified" 
                        />
                        <Column 
                            dataField="vatNumber" 
                            caption="VAT No." 
                        />
                        <Column 
                            dataField="bio" 
                            caption="Bio" 
                        />
                        <Column 
                            dataField="accountVerified" 
                            caption="Bank Verified" 
                        />
                        <Column 
                            dataField="accountNo" 
                            caption="Account No." 
                        />
                         <Column 
                                dataField="createdDateTime" 
                                caption="Account CREATED" 
                                cellRender={this.createdDateTimeDisplay}
                                dataType="date"
                            />
                        <Column 
                            dataField="sortCode" 
                            caption="Sort Code" 
                        />
                        <Column 
                            dataField="active" 
                            caption="Active" 
                        />
                        {this.showAgreementColumns() ? 
                        <Column 
                            dataField="selfBillingAgreementRequired" 
                            caption="Self Bill Required"
                        />
                        : null}

                        {this.showAgreementColumns() ? 
                        <Column dataField="selfBillingAgreementExpiryDate" 
                        caption="Self Bill Expiry Date" 
                        dataType="date"
                        //format="dd/MM/yyyy"
                        cellComponent={AgreementExpiryActionCell} />
                        : null}
                        
                        <FilterRow visible={true} applyFilter="auto" />
                    </DataGrid>
                   : <>
                            <div className="row">
                                <div className="col-2 mt-3 mb-3">
                                    <button
                                        className="btn btn-primary btn--large"
                                        onClick={(e: MouseEvent) => {
                                            e.preventDefault();
                                            this.showProfileNotificationPopup();
                                        }}
                                        disabled={this.state.providerId.length == 0}
                                    >
                                        SEND PROFILE NOTIFICATIONS
                                    </button>
                                </div>
                                <div className="col-2 mt-3 mb-3">
                                    <button
                                        className="btn btn-primary btn--large"
                                        onClick={(e: MouseEvent) => {
                                            e.preventDefault();
                                            this.showBulletinNotificationPopup();
                                        }}
                                        disabled={this.state.providerId.length == 0}
                                    >
                                        SEND BULLETIN NOTIFICATIONS
                                    </button>
                                </div>
                                <div className="col-2 mt-3 mb-3">
                                    <button
                                        className="btn btn-primary btn--large"
                                        disabled={this.isBulkInviteDisabled()}
                                        onClick={() => this.handleBulkInviteClick()}
                                    >
                                        BULK INVITE
                                    </button>
                                </div>
                            </div>
                        <DataGrid
                            dataSource={this.state.ArtistGridDataSource}
                            showBorders={false}
                            showColumnLines={false}
                            hoverStateEnabled={true}
                            columnAutoWidth={true}
                            allowColumnResizing={true}
                            allowColumnReordering={true}
                            onSelectionChanged={this.onSelectionChanged}
                            columnResizingMode={"widget"}
                            noDataText={"No data found for the filter applied - please correct or expand the filters used"}
                            columnMinWidth={100}
                            keyExpr="providerId"
                            onExporting={this.onExporting}
                            ref={(ref) => { this.dataGridRef = ref; }}
                        >
                            <SearchPanel visible={true} placeholder={"Search"} />
                            <GroupPanel visible={true} />
                            <FilterPanel visible={true} />
                            <Editing>
                                <Texts confirmDeleteMessage=""></Texts>
                            </Editing>
                            <Scrolling useNative={true} showScrollbar={"always"} />
                            <Export enabled={true} />
                            <Paging defaultPageSize={10} />
                            <Selection mode="multiple" selectAllMode="allPages" showCheckBoxesMode="always"></Selection>
                            <Pager showPageSizeSelector={true} allowedPageSizes={[5, 10, 20]} showInfo={true} />
                            
                            <Column
                                caption="PROFILE"
                                allowExporting={false}
                                allowFiltering={false}
                                allowSorting={false}
                                allowGrouping={false}
                                cellRender={this.artistProfileDisplay}
                            />
                            {this.showB2cColumns() &&
                                <Column
                                    caption="GET INVITE LINK"
                                    allowExporting={false}
                                    allowFiltering={false}
                                    allowSorting={false}
                                    allowGrouping={false}
                                    cellRender={(rowData: any) => {
                                        return this.renderB2cColumns(rowData, B2cColumnOptions.INVITELINK)
                                    }}
                                />
                            }
                            {this.showB2cColumns() &&
                                <Column
                                    caption="CREATE ACCOUNT"
                                    allowExporting={false}
                                    allowFiltering={false}
                                    allowSorting={false}
                                    allowGrouping={false}
                                    cellRender={(rowData: any) => {
                                        return this.renderB2cColumns(rowData, B2cColumnOptions.CREATEACCOUNT)
                                    }}
                                />
                            }
                            {/* {this.showB2cColumns() &&
                                <Column
                                    caption="RESET PASSWORD"
                                    allowExporting={false}
                                    allowFiltering={false}
                                    allowSorting={false}
                                    allowGrouping={false}
                                    cellRender={(rowData: any) => {
                                        return this.renderB2cColumns(rowData, B2cColumnOptions.RESETPASSWORD)
                                    }}
                                />
                            } */}
                            <Column 
                                dataField="providerId" 
                                caption="Provider Id" 
                            />
                            <Column 
                                dataField="firstName" 
                                caption="First Name" 
                            />
                            <Column 
                                dataField="lastName" 
                                caption="Last Name" 
                            />
                            <Column 
                                dataField="email" 
                                caption="Email" 
                            />
                            <Column 
                                dataField="addressStreet1" 
                                caption="AddressStreet1" 
                            />
                            <Column 
                                dataField="townCity" 
                                caption="Town/City" 
                            />
                            <Column 
                                dataField="mobile" 
                                caption="Mobile" 
                            />
                            <Column 
                                dataField="phone" 
                                caption="Phone" 
                            />
                            <Column 
                                dataField="businessType" 
                                caption="Business Type" 
                            />
                            <Column 
                                dataField="companyNumber" 
                                caption="Company No." 
                            />
                            <Column 
                                dataField="vatNumber" 
                                caption="VAT No." 
                            />
                            <Column 
                                dataField="professionalName" 
                                caption="Professional Name" 
                            />
                            <Column 
                                dataField="typeList" 
                                caption="Sub Type / Type / Other Description " 
                                cellRender={this.typeDisplay}
                                calculateCellValue={this.typeDisplayCalculate}
                                
                            />
                            <Column 
                                dataField="bio" 
                                caption="Bio" 
                            />
                            <Column 
                                dataField="coveragePostCode" 
                                caption="Coverage PostCode" 
                            />
                            <Column 
                                dataField="coverageMiles" 
                                caption="Coverage Miles" 
                            />
                            <Column 
                                dataField="accountVerified" 
                                caption="Bank Verified" 
                            />
                            <Column 
                                dataField="accountNo" 
                                caption="Account No." 
                            />
                            <Column 
                                dataField="createdDateTime" 
                                caption="Account CREATED" 
                                cellRender={this.createdDateTimeDisplay}
                                dataType="date"
                            />
                            <Column 
                                dataField="sortCode" 
                                caption="Sort Code" 
                            />
                                {this.showAgreementColumns() ?
                                    <Column
                                        dataField="selfBillingAgreementRequired"
                                        caption="Self Bill Required"
                                    />
                                    : null}

                                {this.showAgreementColumns() ?
                                    <Column dataField="selfBillingAgreementExpiryDate"
                                        caption="Self Bill Expiry Date"
                                        dataType="date"
                                        // format="dd/MM/yyyy"
                                        cellComponent={AgreementExpiryActionCell} />
                                    : null}
                            <Column 
                                dataField="primaryPhoto" 
                                caption="Primary Photo" 
                            />
                            <Column 
                                dataField="youTube" 
                                caption="YouTube" 
                            />
                            <Column 
                                dataField="idLink" 
                                caption="Id Link" 
                            />
                            <Column 
                                dataField="tracks" 
                                caption="Tracks" 
                            />
                            <Column 
                                dataField="guiltyPleasure" 
                                caption="Guilty Pleasure" 
                            />
                            <Column 
                                dataField="active" 
                                caption="Active" 
                            />
                            <Column 
                                dataField="optOut" 
                                caption="OptOut" 
                            />
                            <Column 
                                dataField="notificationDateTime" 
                                caption="Notification Date Time" 
                                cellRender={this.notiDateTimeDisplay}
                                dataType="date"
                            />
                            <Column
                                dataField="notificationBody"
                                caption="Notification Description"
                                cellRender={this.notiDescriptionCellDisplayContent}
                            />
                            <Column 
                                dataField="lastLoggedIn" 
                                caption="Last LoggedIn" 
                                cellRender={this.notiLastLoginDisplay}
                                dataType="date"
                            />
                            <Column
                                dataField="allowNotifications"
                                caption="Allow Notifications"
                                cellRender={this.allowNotiCellDisplayContent}
                                calculateCellValue={this.allowNotificationsCellCalculate}
                            />
                            {/* <Column></Column> */}
                            <FilterRow visible={true} applyFilter="auto" />
                        </DataGrid>
                        {/* <div>
                            <button
                                className={"btn btn-primary btn--large btn--with-icon col-12 col-sm-6 col-xl-4 mb-3"}                            
                                onClick={(e: MouseEvent) => {
                                    e.preventDefault();
                                    this.showProfileNotificationPopup();
                                }}
                                disabled={this.state.providerId.length==0}
                            >
                                SEND PROFILE NOTIFICATIONS
                            </button>
                            &nbsp;&nbsp;
                            <button
                                className={"btn btn-primary btn--large btn--with-icon col-12 col-sm-6 col-xl-4  mb-3"}
                                onClick={(e: MouseEvent) => {
                                    e.preventDefault();
                                     this.showBulletinNotificationPopup();
                                }}
                                disabled={this.state.providerId.length==0}
                            >
                                SEND BULLETIN NOTIFICATIONS
                            </button>
                            <div className="row">
                                <div className="col-2 mt-3 mb-3">
                                <button
                                className="btn btn-primary btn--large"                          
                                onClick={(e: MouseEvent) => {
                                    e.preventDefault();
                                    this.showProfileNotificationPopup();
                                }}
                                disabled={this.state.providerId.length==0}
                            >
                                SEND PROFILE NOTIFICATIONS
                            </button>
                                </div>
                                <div className="col-2 mt-3 mb-3">
                                <button
                               className="btn btn-primary btn--large"
                                onClick={(e: MouseEvent) => {
                                    e.preventDefault();
                                     this.showBulletinNotificationPopup();
                                }}
                                disabled={this.state.providerId.length==0}
                            >
                                SEND BULLETIN NOTIFICATIONS
                            </button>
                                </div>
                                <div className="col-2 mt-3 mb-3">
                                    <button
                                        className="btn btn-primary btn--large"
                                        disabled={this.isBulkInviteDisabled()}
                                        onClick={() => this.handleBulkInviteClick()}
                                    >
                                        BULK INVITE
                                    </button>
                                </div>
                            </div>
                        </div> */}
                    </>
                )}
                {this.renderInviteLinkPopup()}
                {this.renderErrorPopup()}
                {
                this.state.providerPopupVisibleEntertainment ? (
                        <Popup
                            visible={this.state.providerPopupVisibleEntertainment}
                            onHiding={this.hideInfoProviderArtistProfile}
                            dragEnabled={false}
                            closeOnOutsideClick={true}
                            showTitle={true}
                            showCloseButton={true}
                            // title={this.props.serviceType != "Entertainment" ? (this.props.serviceType == 'ViewClients' ? 'Client Details' :"Provider Details"): "Artist Details"}
                            title = {this.getTitleForPopup()}
                            resizeEnabled={true}
                        >
                            <ScrollView width="100%" height="100%">
                                <ArtistProfileComponent
                                    mode="view"
                                    id={this.state.businessEntityId}
                                    handlePopUpClose={this.handlePopUpClose}
                                    businessEntityId={this.state.businessEntityId}
                                    handleFeedbackUpdate={() => { return null }}
                                    handleAddArtist={() => { return null }}
                                    // serviceType={this.props.serviceType}
                                    serviceType={this.getServiceType()}
                                ></ArtistProfileComponent>
                            </ScrollView>
                        </Popup>
                    ) : (null)
                }
                {
                this.state.providerPopupVisible ? (
                        <Popup
                            visible={this.state.providerPopupVisible}
                            onHiding={this.hideInfoProviderArtistProfile}
                            dragEnabled={false}
                            closeOnOutsideClick={true}
                            showTitle={true}
                            showCloseButton={true}
                            //title={this.props.serviceType != "Entertainment" ? "Provider Details" : "Artist Details"}
                            title = {this.getTitleForPopup()}
                            resizeEnabled={true}
                        >
                            <ScrollView width="100%" height="100%">
                                <ArtistProfileComponent
                                    mode="edit"
                                    id={this.state.businessEntityId}
                                    handlePopUpClose={this.handlePopUpClose}
                                    businessEntityId={this.state.businessEntityId}
                                    handleFeedbackUpdate={() => { return null }}
                                    handleAddArtist={() => { return null }}
                                    //serviceType={this.props.serviceType}
                                    serviceType={this.getServiceType()}
                                ></ArtistProfileComponent>
                            </ScrollView>
                        </Popup>
                    ) : (null)
                }
                <Popup
                    wrapperAttr={{
                        class: "pending-request-panel__raise-query-pop-up",
                    }}
                    visible={this.state.profileNotificationPopupVisible}
                    onHiding={this.handleNotificationPopUpClose}
                    dragEnabled={false}
                    closeOnOutsideClick={false}
                    showTitle={true}
                    showCloseButton={true}
                    title={"Send Profile Notification(s) to "+ this.state.providerId.length + " user(s)"}
                    maxWidth="600px"
                    height="auto"
                >
                    <ScrollView width="100%" height="100%">
                        <div className="pending-request-panel__raise-query">
                            <div className="pending-request-panel__query-box">
                                <div className="card-form__subheading ">Please enter notification title
                                    <TextArea
                                        onValueChanged={this.handleChangeNotiTitle}
                                        value={this.state.title}
                                        height="50%"
                                    />
                                </div>
                                <div className="card-form__subheading mt-5">Please enter notification description
                                    <TextArea
                                        onValueChanged={this.handleChangeNotidescription}
                                        value={this.state.description}
                                        height="50%"
                                    />
                                </div>
                                <div className="row">
                                    <div className="col-12 col-lg-6">
                                        <button className="btn btn-primary btn--large" onClick={this.sendProfileNotification}>
                                            SEND
                                        </button>
                                    </div>
                                    <div className="col-12 col-lg-6">
                                        <button className="btn btn--ghost btn--ghost--teal btn--large" onClick={this.handleNotificationPopUpClose}>
                                            CANCEL
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>    
                    </ScrollView>
                </Popup>
                <Popup
                    wrapperAttr={{
                        class: "pending-request-panel__raise-query-pop-up",
                    }}
                    visible={this.state.bulletinNotificationPopupVisible}
                    onHiding={this.handleNotificationPopUpClose}
                    dragEnabled={false}
                    closeOnOutsideClick={false}
                    showTitle={true}
                    showCloseButton={true}
                    title={"Send Bulletin Notification(s) to "+ this.state.providerId.length + " user(s)"}
                    maxWidth="600px"
                    height="auto"
                >
                    <ScrollView width="100%" height="100%">
                        <div className="pending-request-panel__raise-query">
                            <div className="pending-request-panel__query-box">
                                <div className="card-form__subheading ">Please enter notification title
                                    <TextArea
                                        onValueChanged={this.handleChangeNotiTitle}
                                        value={this.state.title}
                                        height="50%"
                                    />
                                </div>
                                <div className="card-form__subheading mt-5">Please enter notification description
                                    <TextArea
                                        onValueChanged={this.handleChangeNotidescription}
                                        value={this.state.description}
                                        height="50%"
                                    />
                                </div>
                                <div className="row">
                                    <div className="col-12 col-lg-6">
                                        <button className="btn btn-primary btn--large" onClick={this.sendBulletinNotification}>
                                            SEND
                                        </button>
                                    </div>
                                    <div className="col-12 col-lg-6">
                                        <button className="btn btn--ghost btn--ghost--teal btn--large" onClick={this.handleNotificationPopUpClose}>
                                            CANCEL
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>    
                    </ScrollView>
                </Popup>
                <Popup
                    visible={this.state.notiDescriptionPopupVisible}
                    onHiding={this.handleNotificationPopUpClose}
                    dragEnabled={false}
                    closeOnOutsideClick={true}
                    showTitle={true}
                    showCloseButton={true}
                    title={"NOTIFICATION DESCRIPTION"}
                    resizeEnabled={true}
                    width="550px"
                    height="auto"
                >
                    <ScrollView width="100%" height="100%">
                        <div>
                            <div className="card">
                                <div className="card-body">
                                    <p>{this.state.notiDescription}</p>
                                </div>
                            </div>
                        </div>
                    </ScrollView>
                </Popup>
            </div>
        );
    }
}

export default ArtistGrid;
